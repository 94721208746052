<template>
    <div
        ref="byoqContainer"
        v-dark
        class="byoq__container"
    >
        <UpgradeSidePanel
            v-if="showUpgradeSidePanel"
            key="buildYourOwnQuiz"
            :show-full-height="true"
            @close="showUpgradeSidePanel = false"
        />
        <BottomRightBlob class="byoq__bottom-right-blob" />
        <TopLeftBlob class="byoq__top-left-blob" />
        <div v-dark class="byoq">
            <Icon
                v-dark
                type="close"
                class="byoq__close"
                title="Close"
                tabindex="0"
                @click="backToStudy"
                @keydown.enter="backToStudy"
                @mousedown.prevent
            />
            <div class="byoq__inner">
                <Icon
                    v-dark
                    type="close"
                    class="byoq__close byoq__close--mobile"
                    tabindex="0"
                    title="Close"
                    @click="backToStudy"
                    @keydown.enter="backToStudy"
                    @mousedown.prevent
                />
                <div v-dark class="byoq__title">
                    <Icon type="pencil" />
                    <h1>Build Your Own</h1>
                </div>
                <SubjectsSelector
                    :exam-metadata="currentExamMetadata"
                    :initial-show-subjects="false"
                    class="byoq__setting"
                    @selectedSubjects="updateSelectedSubjects"
                />
                <QuizSetting
                    v-model="showAnswersAtEnd"
                    :options="showAnswersAtEndOptions"
                    class="byoq__setting"
                />
                <QuizSetting
                    v-if="!showAnswersAtEnd"
                    v-model="showCheckAnswerButton"
                    :options="showCheckAnswerButtonOptions"
                    class="byoq__setting"
                />
                <div v-dark class="byoq__setting byoq__include">
                    <div class="byoq__include-label">
                        Include:
                    </div>
                    <div class="byoq__include-item">
                        <div class="byoq__include-item-right">
                            <div v-dark class="byoq__include-item-count">
                                {{ questionCounts.new.size }}
                            </div>
                            <Checkbox
                                v-model="includeNew"
                                :is-dark-mode="isDarkMode"
                                class="byoq__include-item-checkbox"
                                aria-labelledby="byoq__include-item-new"
                            />
                        </div>
                        <label 
                            id="byoq__include-item-new" 
                            v-dark
                            class="byoq__include-item-left"
                            :class="{ 'byoq__include-item-left--enabled': includeNew }"
                        >
                            New Questions
                        </label>
                    </div>
                    <div class="byoq__include-item">
                        <div class="byoq__include-item-right">
                            <div v-dark class="byoq__include-item-count">
                                {{ questionCounts.answered.size }}
                            </div>
                            <Checkbox
                                v-model="includeAnswered"
                                :is-dark-mode="isDarkMode"
                                class="byoq__include-item-checkbox"
                                aria-labelledby="byoq__include-item-answered"
                            />
                        </div>
                        <label 
                            id="byoq__include-item-answered"
                            v-dark
                            class="byoq__include-item-left" 
                            :class="{ 'byoq__include-item-left--enabled': includeAnswered }"
                        >
                            Answered Questions
                        </label>
                    </div>
                    <div class="byoq__include-item">
                        <div class="byoq__include-item-right">
                            <div v-dark class="byoq__include-item-count">
                                {{ questionCounts.flagged.size }}
                            </div>
                            <Checkbox
                                v-model="includeFlagged"
                                :is-dark-mode="isDarkMode"
                                class="byoq__include-item-checkbox"
                                aria-labelledby="byoq__include-item-flagged"
                            />
                        </div>
                        <label 
                            id="byoq__include-item-flagged"
                            v-dark
                            class="byoq__include-item-left" 
                            :class="{ 'byoq__include-item-left--enabled': includeFlagged }"
                        >
                            Flagged Questions
                        </label>
                    </div>
                    <div class="byoq__include-item">
                        <div class="byoq__include-item-right">
                            <div v-dark class="byoq__include-item-count">
                                {{ questionCounts.incorrect.size }}
                            </div>
                            <Checkbox
                                v-model="includeIncorrect"
                                :is-dark-mode="isDarkMode"
                                class="byoq__include-item-checkbox"
                                aria-labelledby="byoq__include-item-incorrect"
                            />
                        </div>
                        <label 
                            id="byoq__include-item-incorrect"
                            v-dark
                            class="byoq__include-item-left" 
                            :class="{ 'byoq__include-item-left--enabled': includeIncorrect }"
                        >
                            Incorrect Questions
                        </label>
                    </div>
                </div>
                <div v-dark class="byoq__setting byoq__questions">
                    <label for="questions-slider" class="byoq__questions-left">
                        How many questions?
                    </label>
                    <div class="byoq__questions-right">
                        <PocketInput
                            v-model="questionCountTextInput"
                            v-dark
                            class="byoq__questions-count"
                            :is-dark-mode="isDarkMode"
                            :error="errorFields.includes('minQuizSize')"
                        />
                        <Slider
                            v-if="breakpoint !== 'black-bear'"
                            :key="maxQuizSize" 
                            v-model="questionCountSliderVal"
                            :max="maxQuizSize"
                            :min="minQuizSize"
                            size="small"
                            :is-dark-mode="isDarkMode"
                            class="byoq__questions-slider byoq__questions-slider--small"
                            input-id="questions-slider"
                        />
                        <Slider 
                            v-else
                            :key="`${maxQuizSize}-small`" 
                            v-model="questionCountSliderVal"
                            :max="maxQuizSize"
                            :min="minQuizSize"
                            :is-dark-mode="isDarkMode"
                            class="byoq__questions-slider byoq__questions-slider--large"
                            input-id="questions-slider"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-dark class="byoq__foot">
            <PocketButton
                type="secondary"
                class="byoq__foot-button"
                :is-dark-mode="isDarkMode"
                @click="backToStudy"
            >
                Cancel
            </PocketButton>
            <PocketButton
                class="byoq__foot-button"
                :disabled="hasActiveSubscription
                    && (
                        !maxQuizSize
                        || questionCountSliderVal === 0
                        // keep the Start Quiz button disabled when the text input is an empty string
                        // OR if the user has entered something other than a number into the text input
                        || questionCountSliderVal !== Number(questionCountTextInput)
                    )
                    || bundle?.name === 'Finance'
                "
                :is-loading="isLoading"
                :is-dark-mode="isDarkMode"
                @click="startQuiz"
            >
                {{ hasActiveSubscription ? 'Start Quiz' : 'Upgrade to Premium' }}
            </PocketButton>
        </div>
    </div>
</template>

<script lang="ts">
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Watch } from 'vue-facing-decorator'
import SubjectsSelector from '@/components/Study/SubjectsSelector.vue'
import { examMetadataModule } from '@/store/examMetadata/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import BottomRightBlob from '@/components/Study/BuildYourOwnQuiz/BottomRightBlob.vue'
import TopLeftBlob from '@/components/Study/BuildYourOwnQuiz/TopLeftBlob.vue'
import QuizSetting from '@/components/Study/QuizSetting.vue'
import { subscriptionModule } from '@/store/subscription/module'
import { questionModule } from '@/store/question/module'
import { quizModule } from '@/store/quiz/module'
import { userModule } from '@/store/user/module'
import UpgradeSidePanel from '@/components/Settings/UpgradeSidePanel.vue'
import type { TCreateActiveQuizParams } from '@/store/quiz/actions'
import { stripeModule } from '@/store/stripe/module'
import { bundleModule } from '@/store/bundle/module'
import { screenModule } from '@/store/screen/module'

@Component({
    components: {
        Icon: UIKit.Icon,
        SubjectsSelector,
        BottomRightBlob,
        TopLeftBlob,
        QuizSetting,
        Checkbox: UIKit.Checkbox,
        Slider: UIKit.Slider,
        PocketButton: UIKit.Button,
        PocketInput: UIKit.Input,
        UpgradeSidePanel,
    },
})
export default class BuildYourOwnQuiz extends Vue {
    isLoading = true
    selectedSubjects: string[] = []
    errorFields: string[] = []
    // PocketInput is binding to a string while the Slider components are binding to a number
    questionCountTextInput = '0'
    questionCountSliderVal = 0
    includeNew = true
    includeAnswered = true
    includeIncorrect = true
    includeFlagged = true
    showAnswersAtEnd = false
    showCheckAnswerButton = false
    showUpgradeSidePanel = false
    showAnswersAtEndOptions = [
        {
            value: false,
            label: 'Show answers as I go',
        },
        {
            value: true,
            label: 'Show answers at the end',
        },
    ]
    showCheckAnswerButtonOptions = [
        {
            value: true,
            label: 'Manual Submit (Click "Check Answer" Button)',
        },
        {
            value: false,
            label: 'Automatic Submit (Click Answer)',
        },
    ]

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get hasActiveSubscription () {
        return !!subscriptionModule.getters.getSubscriptionForExamId()
    }

    get currentExamMetadata () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }

    get currentUserExamMetadata () {
        return userExamMetadataModule.getters.getCurrentUserExamMetadata()
    }

    get currentExamMetadataSubjects () {
        return examMetadataModule.state.subjectsWithLevels
    }

    get hasDisabledSubjects () {
        return this.currentExamMetadataSubjects.length > this.selectedSubjects?.length
    }

    get questionCounts () {
        const allExamQuestionSerials = 
            Object.keys(questionModule.getters.getSerialQuestionInfoLib({ questionFilter: 'all' }))
        const bestAnswers = quizModule.getters.getLatestAnswers({ questionFilter: 'all' })

        type TAnswerQuestions = { 
            incorrect: Set<string>
            new: Set<string>
            answered: Set<string>
            flagged: Set<string>
            all: Set<string> 
        }
        return allExamQuestionSerials.reduce<TAnswerQuestions>((acc, serial) => {
            if (this.hasDisabledSubjects && !this.selectedSubjectSerials.has(serial)) {
                return acc
            }
            if (this.currentUserExamMetadata?.flaggedQuestions?.includes(serial)) {
                acc.flagged.add(serial)
            }
            if (serial in bestAnswers) {
                acc.answered.add(serial)

                if (!bestAnswers[serial]?.isCorrect) {
                    acc.incorrect.add(serial)
                }
            } else {
                acc.new.add(serial)
            }

            acc.all.add(serial)
            return acc
        }, {
            incorrect: new Set(),
            new: new Set(),
            answered: new Set(),
            flagged: new Set(),
            all: new Set(),
        })
    }

    get maxQuizSize () {
        const questionSerials: string[] = [
            ...(this.includeNew ? this.questionCounts.new : []),
            ...(this.includeAnswered ? this.questionCounts.answered : []),
            ...((!this.includeAnswered && this.includeIncorrect) ? this.questionCounts.incorrect : []),
            ...(this.includeFlagged ? this.questionCounts.flagged : []),
        ]
        const questionSerialSet = new Set(questionSerials)
        return questionSerialSet.size > 250 ? 250 : questionSerialSet.size
    }

    get minQuizSize () {
        return !this.maxQuizSize ? 0 : 1
    }

    get selectedSubjectSerials () {
        return Object.entries(questionModule.getters.getSerialQuestionInfoLib({ questionFilter: 'all' }))
            .reduce<Set<string>>((acc, [ serial, question ]) => {
            const subjectObject = 
                    this.currentExamMetadataSubjects.find(subject => subject.subjectName === question.subject)
            if (subjectObject && this.selectedSubjects.includes(subjectObject.subjectId)) {
                acc.add(serial)
            }

            return acc
        }, new Set())
    }

    get examMetadataById () {
        return examMetadataModule.getters.getExamMetadataById()
    }

    get bundle () {
        return bundleModule.getters.getBundles()
            .find(b => b.exams.find(e => {
                const bundleExam = this.examMetadataById[e.objectId]
                return this.currentExamMetadata && bundleExam?.examGuid === this.currentExamMetadata.examGuid
            }))
    }

    async mounted () {
        await Promise.all([
            bundleModule.actions.fetchBundles(),
            examMetadataModule.actions.fetchExamMetadata(),
            userExamMetadataModule.actions.fetchUserExamMetadata(),
            userModule.actions.fetchUserData(),
            stripeModule.actions.fetchStripePlans(),
            stripeModule.actions.fetchPaymentMethods(),
        ])
        await questionModule.actions.fetchSerialQuestionInfoLib()
        this.isLoading = false

        // listen for escape key
        window.addEventListener('keydown', this.keydownListener)

        // prevent scrolling outside of modal
        const openBYOQCount = Number(document.body.getAttribute('data-openBYOQCount'))
        document.body.setAttribute('data-openBYOQCount', String(openBYOQCount + 1))
        document.body.classList.add('byoq-modal-open')
    }

    beforeUnmount () {
        const openBYOQCount = Number(document.body.getAttribute('data-openBYOQCount'))
        document.body.setAttribute('data-openBYOQCount', String(openBYOQCount - 1))
        if (openBYOQCount <= 1) {
            document.body.classList.remove('byoq-modal-open')
        }

        window.removeEventListener('keydown', this.keydownListener)
    }

    keydownListener (e: KeyboardEvent) {
        if (e.code === 'Escape') {
            e.preventDefault()
            e.stopPropagation()
            this.backToStudy()
        }
    }

    @Watch('maxQuizSize')
    maxQuizSizeChanged () {
        if (this.maxQuizSize > this.questionCountSliderVal) {
            this.questionCountSliderVal = 15
        }
        if (this.maxQuizSize < this.questionCountSliderVal) {
            this.questionCountSliderVal = this.maxQuizSize
        }
    }

    /**
     * If the user didn't enter a number
     *  - we ignore it (the text input won't show a valid number, but the Start Quiz button will be disabled)
     * If the user entered a number outside our min/max range
     *  - we force the value to be back within the range
     * If the user entered a number within our min/max range
     * - we update the slider to also have that same value
     */
    @Watch('questionCountTextInput')
    questionCountTextInputChanged (val: string) {
        const numVal = parseFloat(val)  // val could be any string
        if (isNaN(numVal)) {
            return
        } else if (numVal > this.maxQuizSize) {
            this.questionCountTextInput = String(this.maxQuizSize)
        } else if (numVal < this.minQuizSize) {
            this.questionCountTextInput = String(this.minQuizSize)
        } else {
            this.questionCountSliderVal = numVal
        }
    }

    @Watch('questionCountSliderVal')
    questionCountSliderValChanged (val: number) {
        // val will always be a number within our slider's min/max range
        this.questionCountTextInput = String(val)
    }

    updateSelectedSubjects (subjects: string[]) {
        this.selectedSubjects = subjects

        this.includeNew = true
        this.includeAnswered = true
        this.includeIncorrect = true
        this.includeFlagged = true
    }

    async backToStudy () {
        this.$router.push({
            name: 'study',
        })
    }

    async startQuiz () {
        this.isLoading = true
        if (!Number(this.questionCountSliderVal) || this.questionCountSliderVal === 0) {
            this.questionCountSliderVal = 1
        }
        if (!this.hasActiveSubscription) {
            this.showUpgradeSidePanel = true
        } else {
            const quizParams: TCreateActiveQuizParams = {
                mode: 'custom',
                questionCount: this.questionCountSliderVal,
                showAnswersAtEnd: this.showAnswersAtEnd,
                showCheckAnswerButton: this.showCheckAnswerButton,
            }
            if (this.selectedSubjects.length) {
                quizParams.subjectIds = this.selectedSubjects
            }
            const include: TCreateActiveQuizParams['include'] = {}
            if (this.includeNew) {
                include.new = true
            }
            if (this.includeAnswered) {
                include.answered = true
            }
            if (this.includeIncorrect) {
                include.incorrect = true
            }
            if (this.includeFlagged) {
                include.flagged = true
            }
            if (Object.keys(include).length) {
                quizParams.include = include
            }

            await quizModule.actions.createActiveQuiz(quizParams)
            this.$router.push({ name: 'quiz' })
        }

        this.isLoading = false
    }
}
</script>
<style lang="scss" scoped>
.byoq {
    padding: 100px 18px 0;
    overflow: auto;
    height: 100%;
    box-sizing: border-box;

    @include breakpoint(black-bear) {
        padding-top: 75px;
        background: $gray-background;
        border-radius: 8px 8px 0 0;
    }

    &--dark {
        @include breakpoint(black-bear) {
            background-color: $charcoal;
        }
    }

    &__close {
        position: absolute;
        right: 29px;
        top: 29px;
        color: $brand-blue;
        width: 30px;
        height: 30px;
        cursor: pointer;

        @include breakpoint(black-bear) {
            top: -69px;
            right: -11px;
            display: none;
        }

        &:hover {
            color: $brand-black;
        }

        &--mobile {
            display: none;

            @include breakpoint(black-bear) {
                display: block;
            }
        }

        &--dark {
            color: $banana-bread;

            &:hover {
                color: $butterscotch;
            }
        }
    }

    &__bottom-right-blob {
        position: absolute;
        bottom: 0;
        right: 0;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__top-left-blob {
        position: absolute;
        top: 0;
        left: 0;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 87px);
        background: $gray-background;

        @include breakpoint(black-bear) {
            padding-top: 10px;
            background: $brand-black;
            height: calc(100% - 78px);
        }

        &--dark {
            background-color: $charcoal;
        }

        :deep(.uikit-page-overlay) {
            height: 100%;
            top: 0;
        }
    }

    &__inner {
        max-width: 492px;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 50px;
        position: relative;
    }

    &__title {
        font-size: 26px;
        line-height: 32px;
        font-weight: 600;
        display: flex;
        align-items: center;
        margin-left: 4px;
        margin-bottom: 40px;
        color: $ash;

        @include breakpoint(black-bear) {
            font-size: 22px;
            line-height: 34px;
            margin-bottom: 16px;
            margin-left: 0;
        }

        svg {
            margin-right: 12px;
            width: 33px;
            height: 32px;

            @include breakpoint(black-bear) {
                width: 24px;
                height: 23px;
            }
        }

        h1 {
            font-weight: 600;
            margin: 0;
            font-size: 26px;
        }

        &--dark {
            color: $fog;
        }
    }

    &__setting {
        margin-bottom: 16px;
    }

    &__include {
        border: 1px solid $gray-divider;
        background: $white;
        border-radius: 6px;
        padding: 14px 15px;

        &--dark {
            background-color: $brand-black;
            border-color: $brand-black;
            color: $white;
        }
    }

    &__include-label {
        font-size: 15px;
        line-height: 21px;
        margin-bottom: 10px;
    }

    &__include-item {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: center;
        margin-bottom: 18px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__include-item-left {
        font-size: 15px;
        line-height: 20px;
        color: $slate-01;

        &--enabled {
            color: $brand-black;

            &--dark {
                color: $white;
            }
        }
    }

    &__include-item-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__include-item-count {
        font-size: 14px;
        line-height: 17px;
        margin-right: 13px;
        color: $slate-01;

        &--dark {
            color: $pewter;
        }
    }

    &__questions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $gray-divider;
        background: $white;
        border-radius: 6px;
        padding: 14px 15px;

        @include breakpoint(black-bear) {
            display: block;
            text-align: center;
        }

        &--dark {
            background-color: $brand-black;
            border-color: $brand-black;
            color: $white;
        }
    }

    &__questions-left {
        font-size: 15px;
        line-height: 20px;

        @include breakpoint(black-bear) {
            margin-top: 16px;
            margin-bottom: 18px;
        }
    }

    &__questions-right {
        display: flex;
        flex-direction: row-reverse;

        @include breakpoint(black-bear) {
            display: block;
        }
    }

    &__questions-slider {
        margin-right: 23px;

        @include breakpoint(black-bear) {
            margin-right: 12px;
            margin-bottom: 16px;
        }

        &--large {
            @include breakpoint(black-bear) {
                display: flex;
            }
        }
    }

    &__questions-count {
        background: $gray-background;
        width: 53px;
        height: 31px;
        border-radius: 6px;
        text-align: center;
        font-size: 20px;
        line-height: 31px;
        font-weight: 600;

        &--dark {
            background-color: $charcoal;
            color: $white;
        }

        @include breakpoint(black-bear) {
            background: none;
            font-size: 40px;
            line-height: 50px;
            letter-spacing: -2px;
            width: 53px;
            text-align: center;
            margin: auto auto 26px;
        }
    }

    &__foot {
        border-top: 1px solid $gray-divider;
        position: absolute;
        bottom: -87px;
        left: 0;
        width: 100%;
        height: 87px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $white;

        @include breakpoint(black-bear) {
            bottom: -68px;
            height: 68px;
        }

        &--dark {
            background-color: $mariner;
            border-top-color: $mariner;
        }
    }

    &__foot-button {
        margin: 0 4px;
    }
}
</style>