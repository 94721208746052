<script setup lang="ts">
import { userModule } from '@/store/user/module'
import { computed } from 'vue'
import UIKit from '@pocketprep/ui-kit'
const {
    ModalContainer,
    Button: PocketButton,
} = UIKit

defineProps<{
    isSwitchingExams: boolean
    examName: string | undefined
    discontinuedDate: string
}>()

const emit = defineEmits<{
    'switchExamVersion': []
}>()

const isDarkMode = computed(() => {
    return userModule.state.settings.isDarkMode
})
</script>

<template>
    <Portal to="modal">
        <ModalContainer
            key="discontinuedExamVersionModal"
            :show-close-button="false"
            :is-dark-mode="isDarkMode"
        >
            <div class="discontinued-exam-version-modal">
                <div class="discontinued-exam-version-modal__title">
                    Exam Version Discontinued
                </div>
                <div class="discontinued-exam-version-modal__content">
                    {{examName}} launched a new version of their exam. 
                    Our prep for the previous version was discontinued on {{ discontinuedDate }}. 
                    You have been switched to the current version. 
                </div>
                <PocketButton
                    class="discontinued-exam-version-modal__sunset-close-btn"
                    :isDarkMode="isDarkMode"
                    :is-loading="isSwitchingExams"
                    @click="emit('switchExamVersion')"
                >
                    Close
                </PocketButton>
            </div>
        </ModalContainer>
    </Portal>
</template>

<style lang="scss" scoped>
.discontinued-exam-version-modal {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0.3px;
        text-align: center;
        margin-top: 36px;
        margin-bottom: 12px;
    }

    &__content {
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        width: 298px;
        margin: 0 38px 32px 38px;
    }

    &__sunset-close-btn {
        margin-bottom: 24px;
    }
}
</style>
