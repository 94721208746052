<template>
    <div
        ref="dropdown"
        v-dark
        class="filter-dropdown"
    >
        <div
            v-dark
            class="filter-dropdown__label"
            :class="{ 'filter-dropdown__label--active': showQuestionTypeOptions }"
            tabindex="0"
            :aria-label="`${showQuestionTypeOptions ? 'Hide' : 'Show'} question type filters`"
            @click="showQuestionTypeOptions = !showQuestionTypeOptions; showSubjectOptions = false"
            @keypress.enter="showQuestionTypeOptions = !showQuestionTypeOptions; showSubjectOptions = false"
            @mousedown.prevent
        >
            <span>Filter by Question Type</span>
            <Icon
                v-dark
                type="accordionArrow"
                class="filter-dropdown__arrow"
            />
        </div>
        <Radio
            v-if="showQuestionTypeOptions"
            v-model="questionType"
            aria-live="polite"
            :data="questionTypeOptions"
            :is-dark-mode="isDarkMode"
            class="filter-dropdown__options"
        />
        <div
            v-dark
            class="filter-dropdown__label"
            :class="{ 'filter-dropdown__label--active': showSubjectOptions }"
            tabindex="0"
            :aria-label="`${showSubjectOptions ? 'Hide' : 'Show'} subject filters`"
            @click="showSubjectOptions = !showSubjectOptions; showQuestionTypeOptions = false"
            @keypress.enter="showSubjectOptions = !showSubjectOptions; showQuestionTypeOptions = false"
            @mousedown.prevent
        >
            <span>Filter by Subject</span>
            <Icon
                v-dark
                type="accordionArrow"
                class="filter-dropdown__arrow"
            />
        </div>
        <div
            v-if="showSubjectOptions"
            aria-live="polite"
            class="filter-dropdown__subject-options"
        >
            <CheckboxOption
                v-for="subjectOption in subjectOptions"
                :key="subjectOption"
                :label="subjectOption"
                class="filter-dropdown__subject-option"
                :class="{
                    'filter-dropdown__subject-option--force-break': !stringHasSpaces(subjectOption)
                }"
                :is-dark-mode="isDarkMode"
                :modelValue="subjects.includes(subjectOption)"
                @update:modelValue="toggleSubjectOption(subjectOption, $event)"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { examMetadataModule } from '@/store/examMetadata/module'
import { userModule } from '@/store/user/module'
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Watch, Prop, Emit } from 'vue-facing-decorator'

@Component({
    components: {
        Radio: UIKit.Radio,
        CheckboxOption: UIKit.CheckboxOption,
        Icon: UIKit.Icon,
    },
})
export default class FilterDropdown extends Vue {
    @Prop() defaultQuestionType!: string
    @Prop() defaultSubjects!: string[]

    questionTypeOptions = [
        { label: 'All Answered', value: 'all' },
        { label: 'Correct', value: 'correct' },
        { label: 'Incorrect', value: 'incorrect' },
        { label: 'Flagged', value: 'flagged' },
    ]
    questionType: { label: string; value: string } | null = null
    subjects: string[] = []
    subjectsSet: Set<string> = new Set()
    showQuestionTypeOptions = true
    showSubjectOptions = false

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get subjectOptions () {
        return examMetadataModule.state.subjectsWithLevels.map(subject => subject.subjectName)
    }

    mounted () {
        this.questionType = this.questionTypeOptions.find(
            o => o.value === this.defaultQuestionType
        ) || null
        this.subjects = [ ...this.defaultSubjects ]
        this.subjectsSet = new Set(this.defaultSubjects)

        examMetadataModule.actions.fetchExamMetadata()
        document.addEventListener('keydown', this.keydownListener)
        document.addEventListener('click', this.clickListener)
    }

    beforeUnmount () {
        document.removeEventListener('keydown', this.keydownListener)
        document.removeEventListener('click', this.clickListener)
    }
    
    stringHasSpaces (val: string) {
        return val.includes(' ')
    }

    keydownListener (e: KeyboardEvent) {
        if (e.code === 'Escape') {
            e.stopPropagation()
            this.emitClose()
        }
    }

    clickListener (e: MouseEvent) {
        const targetEl = e.target as HTMLElement
        const dropdownEl = this.$refs.dropdown as HTMLElement
        if (!dropdownEl?.contains(targetEl) 
                && !targetEl?.closest('.question-review__side-bar-header')
                && document.body.contains(targetEl)
        ) {
            this.emitClose()
        }
    }

    toggleSubjectOption (subject: string, value: boolean) {
        if (value) {
            this.subjectsSet.add(subject)
        } else {
            this.subjectsSet.delete(subject)
        }
        this.subjects = [ ...this.subjectsSet ]
    }

    @Watch('questionType')
    @Watch('subjects', { deep: true })
    filterChanged () {
        this.emitFilterChange({
            questionType: this.questionType?.value,
            subjects: this.subjects,
        })
    }

    @Emit('close')
    emitClose () {
        return true
    }

    @Emit('filterChange')
    emitFilterChange (filterValues: { questionType: string | undefined; subjects: string[] }) {
        return filterValues
    }
}
</script>

<style lang="scss" scoped>
.filter-dropdown {
    background: $white;
    border-radius: 8px;
    box-shadow: 0 6px 22px 0 rgba($brand-black, 0.1);
    padding: 9px 20px;
    box-sizing: border-box;
    width: 100%;
    font-weight: 500;
    max-height: 305px;
    overflow: auto;

    &--dark {
        background: $brand-black;
    }

    &__label {
        font-size: 10px;
        letter-spacing: 0.2px;
        line-height: 30px;
        font-weight: 600;
        cursor: pointer;
        color: $steel;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        outline: none;
        position: relative;

        &--active svg {
            transform: rotate(180deg);
        }

        &:focus {
            &::before {
                content: '';
                left: -6px;
                top: -2px;
                width: calc(100% + 12px);
                height: 100%;
                border: 1px solid $brand-blue;
                position: absolute;
                border-radius: 6px;
            }
        }

        &--dark {
            color: $pewter;

            &:focus::before {
                border-color: $banana-bread;
            }
        }

        span {
            margin-right: 6px;
        }

        svg {
            color: $brand-blue;
            width: 7px;
        }
    }

    &__arrow {
        &--dark {
            color: $banana-bread !important;
        }
    }

    &__options {
        margin-bottom: 6px;
    }

    &__subject-option {
        &--force-break {
            word-break: break-all;
        }
    }
}
</style>