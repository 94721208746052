<template>
    <div
        id="settings"
        v-dark 
        class="settings"
    >
        <div v-dark class="settings__sidebar">
            <h3 v-dark class="settings__sidebar-heading">
                Settings
            </h3>
            <ul class="settings__sidebar-nav">
                <li>
                    <RouterLink
                        v-slot="{ isActive, navigate, href }"
                        custom
                        :to="{ name: 'account' }"
                    >
                        <a
                            v-dark
                            :href="href"
                            :class="{ 'settings__sidebar-nav-link--active': isActive }"
                            class="settings__sidebar-nav-link"
                            @click="navigate"
                            @mousedown.prevent
                        >
                            <span>Account</span>
                        </a>
                    </RouterLink>
                </li>
                <li>
                    <RouterLink
                        v-slot="{ isActive, navigate, href }"
                        custom
                        :to="{ name: 'exams' }"
                    >
                        <a
                            v-dark
                            :href="href"
                            :class="{ 'settings__sidebar-nav-link--active': isActive }"
                            class="settings__sidebar-nav-link"
                            @click="navigate"
                            @mousedown.prevent
                        >
                            <span>Exams</span>
                        </a>
                    </RouterLink>
                </li>
                <li>
                    <RouterLink
                        v-slot="{ isActive, navigate, href }"
                        custom
                        :to="{ name: 'purchases' }"
                    >
                        <a
                            v-dark
                            :href="href"
                            :class="{ 'settings__sidebar-nav-link--active': isActive }"
                            class="settings__sidebar-nav-link"
                            @click="navigate"
                            @mousedown.prevent
                        >
                            <span>Purchases</span>
                        </a>
                    </RouterLink>
                </li>
                <li v-if="showShareTab">
                    <RouterLink
                        v-slot="{ isActive, navigate, href }"
                        custom
                        :to="{ name: 'share' }"
                    >
                        <a
                            v-dark
                            :href="href"
                            :class="{ 'settings__sidebar-nav-link--active': isActive }"
                            class="settings__sidebar-nav-link"
                            @click="navigate"
                            @mousedown.prevent
                        >
                            <span>Share</span>
                            <Tag
                                class="settings__referral-percent-span"
                                :size="'medium'"
                                :is-dark-mode="isDarkMode"
                            >
                                <template #tagMessage>
                                    Give 20% Off
                                </template>
                            </Tag>
                        </a>
                    </RouterLink>
                </li>
            </ul>
            <div class="settings__sidebar-foot">
                <PocketLink
                    :href="`https://www.pocketprep.com/terms-of-service?${buildUTMParamString({
                        utm_campaign: 'app_settings',
                        utm_content: 'settings_terms'
                    })}`"
                    target="_blank"
                    class="settings__foot-link"
                    :is-dark-mode="isDarkMode"
                    @mousedown.prevent
                >
                    <Icon type="lock" />
                    <div>Privacy Policy &amp; Terms of Service</div>
                </PocketLink>
                <PocketLink
                    :href="`https://www.pocketprep.com/accessibility-statement?${buildUTMParamString({
                        utm_campaign: 'app_settings',
                        utm_content: 'a11y_statement'
                    })}`"
                    target="_blank"
                    class="settings__foot-link"
                    :is-dark-mode="isDarkMode"
                    @mousedown.prevent
                >   
                    <Icon type="heart" /> Accessibility Statement
                </PocketLink>
                <div v-dark class="settings__version">
                    v{{ version }}
                </div>
            </div>
        </div>
        <div v-dark class="settings__content">
            <RouterView />
        </div>
        <div v-dark class="settings__foot">
            <PocketLink
                :href="`https://www.pocketprep.com/terms-of-service?${buildUTMParamString({
                    utm_campaign: 'app_settings',
                    utm_content: 'settings_terms'
                })}`"
                target="_blank"
                class="settings__foot-link"
                :is-dark-mode="isDarkMode"
                @mousedown.prevent
            >
                <Icon type="lock" /> Privacy Policy &amp; Terms of Service
            </PocketLink>
            <PocketLink
                :href="`https://www.pocketprep.com/accessibility-statement?${buildUTMParamString({
                    utm_campaign: 'app_settings',
                    utm_content: 'a11y_statement'
                })}`"
                target="_blank"
                class="settings__foot-link"
                :is-dark-mode="isDarkMode"
                @mousedown.prevent
            >
                <Icon type="heart" /> Accessibility Statement
            </PocketLink>
            <div v-dark class="settings__version">
                v{{ version }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { userModule } from '@/store/user/module'
import { buildUTMParamString } from '@/store/utils'
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component } from 'vue-facing-decorator'

@Component({
    components: {
        Icon: UIKit.Icon,
        PocketLink: UIKit.Link,
        Tag: UIKit.Tag,
    },
})
export default class Settings extends Vue {
    version = import.meta.env.VUE_APP_VERSION

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    // show user the share tab if they have THEIR referral code to share
    get showShareTab () {
        return userModule.getters.getReferralInfo()
    }

    async mounted () {
        await Promise.all([
            userModule.actions.fetchUserData(),
        ])
   
        const settingsEl = document.getElementById('settings')
        if (settingsEl) {
            settingsEl.scrollIntoView()
        }
    }

    buildUTMParamString = buildUTMParamString
}
</script>
<style lang="scss" scoped>
.settings {
    display: flex;
    min-height: calc(100vh - 54px);
    margin: 0 auto;

    &--dark {
        color: $white;
        background: $evening;
    }

    @include breakpoint(brown-bear) {
        flex-direction: column;
    }

    &__sidebar {
        background: $gray-background;
        width: 330px;
        min-width: 202px;
        z-index: 1;
        position: relative;
        padding: 43px 5px 43px 45px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        @include breakpoint(grizzly-bear) {
            padding: 48px 5px 48px 42px;
        }

        @include breakpoint(brown-bear) {
            flex-direction: row;
            width: 100%;
            padding: 20px 25px 11px;
        }

        @include breakpoint(black-bear) {
            padding: 18px 30px 10px;
        }

        &--dark {
            background: $brand-black;
            border-bottom-color: $brand-black;

            @include breakpoint(black-bear) {
                background: $brand-black;
            }
        }
    }

    &__sidebar-heading {
        color: $slate-01;
        font-size: 13px;
        text-transform: uppercase;
        line-height: 16px;
        letter-spacing: 2px;
        font-weight: 700;
        margin: 0 0 16px 0;

        &--dark {
            color: $pewter;
        }

        @include breakpoint(brown-bear) {
            margin-right: 29px;
            margin-bottom: 0;
            display: none;
        }
    }

    &__sidebar-nav {
        list-style: none;
        padding: 0;
        margin: 0;
        flex: 1;

        @include breakpoint(brown-bear) {
            display: flex;
        }
    }

    &__sidebar-nav-link {
        margin-bottom: 12px;
        display: block;
        text-decoration: none;
        outline: none;
        color: $slate-01;
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        position: relative;

        @include breakpoint(brown-bear) {
            margin-bottom: 0;
            margin-right: 16px;
        }

        @media (hover: hover) {
            &:hover:not(.settings__sidebar-nav-link--active) {
                color: $brand-blue;;
            }
        }

        &:focus {
            span::before {
                content: '';
                position: absolute;
                left: -3px;
                width: calc(100% + 4px);
                top: -1px;
                height: 21px;
                border: 1px solid $brand-blue;
                border-radius: 4px;
            }
        }

        &--dark {
            color: rgba($white, 0.64);

            &:focus {
                span::before {
                    border-color: $banana-bread;
                }
            }

            @media (hover: hover) {
            &:hover:not(.settings__sidebar-nav-link--active--dark) {
                color: $banana-bread;
            }
        }
        }

        &--active {
            color: $brand-black;

            @include breakpoint(brown-bear) {
                &::before {
                    content: '';
                    position: absolute;
                    bottom: -12px;
                    left: 0;
                    height: 3px;
                    width: 100%;
                    background: $brand-black;
                }
            }

            &--dark {
                color: $white;

                @include breakpoint(brown-bear) {
                    &::before {
                        background: $white;
                    }
                }
            }
        }

        span {
            position: relative;
        }
    }

    &__sidebar-foot {
        padding-right: 20px;

        @include breakpoint(brown-bear) {
            display: none;
        }
    }

    &__referral-percent-span {
        margin-left: 6px;

        @include breakpoint(brown-bear) {
            display: none;
        }
    }

    &__foot {
        display: none;
        padding: 22px 30px;
        background: $gray-background;

        @include breakpoint(brown-bear) {
            display: block;
        }

        @include breakpoint(black-bear) {
            border-top: 1px solid $gray-divider;
        }

        &--dark {
            border-top-color: $mariner;
            background: $mariner;
        }
    }

    &__foot-link {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        text-decoration: none;
        margin-bottom: 5px;
        padding: 2px 0;

        svg {
            width: 21px;
            height: 22px;
            vertical-align: top;
            margin-right: 5px;
        }

        div {
            width: calc(100% - 26px);
        }
    }

    &__version {
        background: $gray-divider;
        border-radius: 4px;
        font-size: 13px;
        line-height: 20px;
        color: $slate-03;
        text-align: center;
        padding: 0 4px;
        display: inline-block;
        margin-top: 19px;

        &--dark {
            background: rgba($white, 0.12);
            color: $white;
        }
    }

    &__content {
        padding: 51px 53px 76px;
        max-width: 873px;
        flex: 1;
        margin: 0 auto;
        position: relative;
        box-sizing: border-box;
        width: 100%;

        @include breakpoint(grizzly-bear) {
            padding: 48px 53px;
            max-width: 836px;
        }

        @include breakpoint(brown-bear) {
            padding: 29px 25px 40px;
            max-width: none;
        }

        @include breakpoint(black-bear) {
            padding: 36px 30px 50px;
        }

        &--dark {
            @include breakpoint(black-bear) {
                background: $charcoal;
            }
        }
    }
}
</style>