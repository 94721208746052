<template>
    <div v-dark class="quiz-result">
        <Portal to="quizResultHeader">
            <QuizResultHeader
                class="quiz-result__header"
                :quiz="currentQuiz"
                :can-retake-quiz="canRetakeQuiz"
                :level-up-quiz-level="levelUpQuizLevel"
                :num-of-levels-in-subject="numOfLevelsInSubject"
                @retake="retakeQuiz"
                @close="closeResults"
                @goToLevelUp="goToLevelUp"
            />
        </Portal>
        <QuizResultBlob
            v-if="!isLoading"
            class="quiz-result__blob"
            :color="scoreColor"
        />
        <div v-dark class="quiz-result__banner">
            <img
                v-if="!isDarkMode && !isLoading && isHundredPercent && showConfetti && !retakenQuiz"
                src="@/assets/celebrations/lightModeConfetti.gif"
                class="quiz-result__celebration-gif"
                alt=""
            >
            <img
                v-if="isDarkMode && !isLoading && isHundredPercent && showConfetti && !retakenQuiz"
                src="@/assets/celebrations/darkModeConfetti.gif"
                class="quiz-result__celebration-gif"
                alt=""
            >
            <div class="quiz-result__stats">
                <Gauge
                    :key="`${quizScore}-${communityScore}-${gaugeSize}`"
                    class="quiz-result__gauge"
                    :score="quizScore"
                    :theme="isDarkMode ? 'dark' : 'light'"
                    :community-score="communityScore"
                    :size="gaugeSize"
                />
                <div class="quiz-result__small-stats">
                    <Stat
                        :theme="isDarkMode ? 'dark' : 'light'"
                        class="quiz-result__correct"
                        label="Answered Correctly"
                    >
                        <template #value>
                            {{ correctCount }}
                            <span class="quiz-result__correct-slash">/</span>
                            {{ totalCount }}
                        </template>
                    </Stat>
                    <Stat
                        :theme="isDarkMode ? 'dark' : 'light'"
                        class="quiz-result__time-per-question"
                        :value="timePerQuestion"
                        label="Avg Time Per Question"
                    />
                    <Stat
                        :theme="isDarkMode ? 'dark' : 'light'"
                        class="quiz-result__quiz-time"
                        :value="quizTime"
                        :label="isMockExam ? 'Exam Time' : 'Quiz Time'"
                    />
                </div>
            </div>
            <div class="quiz-result__mobile-stats">
                <div
                    v-dark
                    class="quiz-result__mobile-stat"
                    :class="{ [`quiz-result__mobile-stat--${scoreColor}`]: true }"
                >
                    <div v-dark class="quiz-result__mobile-stat-number">
                        {{ correctCount }}
                        <span class="quiz-result__correct-slash">/</span>
                        {{ totalCount }}
                    </div>
                    <div v-dark class="quiz-result__mobile-stat-label">
                        Answered Correctly
                    </div>
                </div>
                <div
                    v-dark
                    class="quiz-result__mobile-stat"
                    :class="{ [`quiz-result__mobile-stat--${scoreColor}`]: true }"
                >
                    <div v-dark class="quiz-result__mobile-stat-number">
                        {{ quizTime }}
                    </div>
                    <div v-dark class="quiz-result__mobile-stat-label">
                        {{ isMockExam ? 'Exam Time' : 'Quiz Time' }}
                    </div>
                </div>
            </div>
            <div
                v-if="!isLoading && (showQuizCTABanner || showMockExamCTABanner)"
                v-dark 
                class="quiz-result__share-cta"
            >
                <img 
                    class="quiz-result__tada" 
                    src="/img/study/tada.png" 
                    alt=""
                >
                <div v-dark class="quiz-result__share-cta-message">
                    {{ showQuizCTABanner ? 'You are crushing it!' : 'Congrats! ' }} 
                    Share the love. Give 20% off a new subscription.
                    <PocketButton
                        class="quiz-result__share-cta-btn"
                        :class="{
                            'quiz-result__share-cta-btn--mock-exam': showMockExamCTABanner,
                        }" 
                        type="tertiary"
                        :is-dark-mode="isDarkMode"
                        @click="goToSharePage"
                    >
                        Refer Friends
                    </PocketButton>
                </div>
            </div>
            <div
                v-else-if="showWeekly100Banner"
                v-dark
                class="quiz-result__weekly-100-banner"
            >
                <IconBadge v-dark class="quiz-result__badge-icon" />
                <div v-dark class="quiz-result__weekly-100-message">
                    You earned a Weekly 100 badge!
                </div>
            </div>
            <PocketButton
                v-if="isLevelUpQuiz"
                :is-dark-mode="isDarkMode"
                class="quiz-result__mobile-cta"
                :class="{
                    'quiz-result__mobile-cta--show-banner': showQuizCTABanner || showWeekly100Banner,
                }" 
                @click="goToLevelUp"
            >
                Keep Studying
            </PocketButton>
            <PocketButton
                v-if="!isLoading && canRetakeQuiz && !isMockExam"
                :is-dark-mode="isDarkMode"
                class="quiz-result__mobile-cta"
                :class="{
                    'quiz-result__mobile-cta--show-banner': showQuizCTABanner || showWeekly100Banner,
                }" 
                @click="retakeQuiz"
            >
                Retake Quiz
            </PocketButton>
            <SubjectInsights
                v-if="isMockExam" 
                class="quiz-result__subject-insights"
                :class="{
                    'quiz-result__subject-insights--banner': !isLoading && (
                        showMockExamCTABanner || showWeekly100Banner
                    ),
                }" 
                :is-mock-exam="isMockExam"
                :mock-exam-subject-insights="mockExamSubjectInsights"
            />
            <div 
                class="quiz-result__tabs"
                :class="{
                    'quiz-result__tabs--mock-exam': isMockExam,
                    'quiz-result__tabs--show-banner': showQuizCTABanner || showWeekly100Banner,
                }" 
                role="tablist"
            >
                <ReviewTab
                    v-for="tab in tabs"
                    :key="tab"
                    :is-active="activeTab === tab"
                    :label="tab"
                    :count="questionCounts[tab]"
                    role="tab"
                    :aria-selected="activeTab === tab ? 'true' : 'false'"
                    @keydown.enter="activeTab = tab"
                    @click="activeTab = tab"
                />
            </div>
        </div>
        <div
            v-if="!currentTabQuestions.length"
            class="quiz-result__blank"
        >
            <EmptyState
                v-if="activeTab === 'All'"
                title="Let’s get studying!"
                message="Then come back here to review all of your answered questions."
                :is-dark-mode="isDarkMode"
            />
            <EmptyState
                v-else-if="activeTab === 'Flagged'"
                state="flagged"
                :title="breakpoint !== 'black-bear' ? 'You haven’t flagged any questions.' : '0 Flagged Questions'"
                :message="breakpoint !== 'black-bear' ? 
                    'Click the flag icon at the bottom of any question and you can review them here.' : 
                    'Tap the flag icon at the bottom of any question and you can review them here.'
                "
                backgroundColor="white"
                :is-dark-mode="isDarkMode"
            />
            <EmptyState
                v-if="activeTab === 'Incorrect'"
                state="incorrect"
                :title="breakpoint !== 'black-bear' ? 
                    'You don’t have any incorrect questions.' : '0 Incorrect Questions'"
                message="Impressive!"
                backgroundColor="white"
                :is-dark-mode="isDarkMode"
            />
            <EmptyState
                v-else-if="activeTab === 'Correct'"
                state="correct"
                :title="breakpoint !== 'black-bear' ? 
                    'You don’t have any correct questions.' : '0 Correct Questions'"
                backgroundColor="white"
                :is-dark-mode="isDarkMode"
            />
        </div>
        <div class="quiz-result__questions">
            <QuestionCard
                v-for="question in currentTabQuestions"
                :key="question.serial"
                class="quiz-result__question"
                :question="question"
                @click="questionClicked(question)"
                @keydown.enter="questionClicked(question)"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator'
import QuizResultHeader from '@/components/QuizResult/QuizResultHeader.vue'
import { quizModule } from '@/store/quiz/module'
import { userModule } from '@/store/user/module'
import UIKit, { utils } from '@pocketprep/ui-kit'
import type { TActiveQuiz } from '@/store/quiz/state'
import { 
    allowedQuestionsPerMinute, 
    getScoreRange,
    getHoursMinutesSeconds, 
    localISODateString, 
} from '@/utils'
import { globalQuestionMetricModule } from '@/store/globalQuestionMetric/module'
import { screenModule } from '@/store/screen/module'
import { questionModule } from '@/store/question/module'
import Gauge from '@/components/Gauge.vue'
import Stat from '@/components/Stat.vue'
import SubjectInsights from '@/components/SubjectInsights.vue'
import ReviewTab from '@/components/ReviewTab.vue'
import QuestionCard, { type TQuestionCardInfo } from '@/components/QuestionCard.vue'
import IconBadge from '@/components/IconBadge.vue'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { subscriptionModule } from '@/store/subscription/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import { bundleModule } from '@/store/bundle/module'
import QuizResultBlob from '@/assets/quiz-result/QuizResultBlob.vue'
import NoResultsBlob from '@/assets/review/NoResultsBlob.vue'
import type { Study } from '@pocketprep/types'
import { qotdModule } from '@/store/qotd/module'
import { analyticsModule } from '@/store/analytics/module'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'
import { logger } from '@/elk'
import { stripHtmlTags } from '@/utils'

export type TTabName = 'All' | 'Flagged' | 'Incorrect' | 'Correct'
type TMockExamSubjects = {
    id: string
    name: string
    correctCount: number
    answeredCount: number
}

@Component({
    components: {
        QuizResultHeader,
        Gauge,
        Stat,
        SubjectInsights,
        ReviewTab,
        QuestionCard,
        QuizResultBlob,
        NoResultsBlob,
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
        EmptyState: UIKit.EmptyState,
        IconBadge,
    },
})
export default class QuizResult extends Vue {
    isLoading = true
    tabs: TTabName[] = [
        'All',
        'Flagged',
        'Incorrect',
        'Correct',
    ]
    activeTab: TTabName = 'All'
    getScoreRange = getScoreRange
    brandColors = BrandColors
    fromName: string | null = null
    confettiTimerInterval: ReturnType<typeof setInterval> | null = null
    confettiTime = 0
    showConfetti = true
    showQuizCTABanner = false
    showMockExamCTABanner = false
    version = import.meta.env.VUE_APP_VERSION
    badgeExperimentVariantValue: 'badges-not-visible' | 'badges-visible' | null = null

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get uem () {
        return userExamMetadataModule.getters.getCurrentUserExamMetadata()
    }

    get quizId () {
        return this.$route.params['quizId'] as string
    }

    get hasSubscription () {
        return subscriptionModule.getters.getSubscriptionForExamId()
    }

    get currentQuiz () {
        return quizModule.getters.getQuizById(this.quizId)
    }

    get isMockExam () {
        return this.currentQuiz?.mode === 5
    }

    get isLevelUpQuiz () {
        return this.currentQuiz?.mode === 6
    }

    get canRetakeQuiz () {
        return !this.isLoading 
            && !this.allTabQuestions.All.some(q => q.isLocked)
            && this.currentQuiz
            && utils.studyModes[this.currentQuiz.mode as -1 | 0 | 2 | 3 | 4 | 10 ]
            && !this.isLevelUpQuiz
            && !this.prebuiltQuiz
    }

    get currentQuizQuestions () {
        if (!this.currentQuiz) {
            return []
        }

        const answerSerials = this.currentQuiz.answers.map(ans => ans.questionSerial)
        const allQuestions = quizModule.getters.getAnsweredQuestions()

        return allQuestions.filter(q => answerSerials.includes(q.serial))
    }

    get currentQuizQuestionsSerialLib () {
        if (!this.currentQuiz) {
            return {}
        }

        return this.currentQuizQuestions.reduce<{ [serial: string]: Study.Class.QuestionJSON }>((acc, q) => {
            acc[q.serial] = q

            return acc
        }, {})
    }

    get isHundredPercent () {
        return this.correctCount === this.totalCount && this.correctCount >= 10
    }

    get retakenQuiz () {
        return this.fromName && this.fromName === 'quiz-result'
    }

    get currentQuizAnswers () {
        if (!this.currentQuiz) {
            return []
        }

        return this.currentQuiz.answers
    }

    get correctAnswers () {
        return this.currentQuizAnswers.filter(ans => ans.isCorrect).map(ans => ans.questionSerial)
    }

    get flaggedSerials () {
        return userExamMetadataModule.getters.getFlaggedQuestions()
    }

    get allTabQuestions () {
        return this.currentQuizAnswers.reduce<{ [key in TTabName]: TQuestionCardInfo[] }>((acc, a) => {
            const q = this.currentQuizQuestionsSerialLib[a.questionSerial]
            if (!q) {
                return acc
            }
            const subject = q.subject as Study.Class.SubjectJSON
            const subjectName = subject.name
            const questionScenarioPartLabel = this.scenarioPartLabel(q)
            const prompt = stripHtmlTags(q.prompt)

            const questionCardInfo: TQuestionCardInfo = {
                serial: q.serial,
                subjectName: subjectName,
                prompt,
                isFlagged: this.flaggedSerials.includes(q.serial),
                isCorrect: this.correctAnswers.includes(q.serial),
                isLocked: !this.hasSubscription && !q.isFree && a.questionSerial !== this.todaysQotD?.serial,
                scenarioPartLabel: questionScenarioPartLabel,
            }

            if (questionCardInfo.isFlagged) {
                acc.Flagged.push(questionCardInfo)
            }

            if (questionCardInfo.isCorrect) {
                acc.Correct.push(questionCardInfo)
            } else {
                acc.Incorrect.push(questionCardInfo)
            }

            acc.All.push(questionCardInfo)
            return acc
        }, { 'All': [], 'Flagged': [], 'Incorrect': [], 'Correct': [] })
    }

    get mockExamSubjectInsights () {
        const mockExamSubjects: TMockExamSubjects[] = []

        this.allTabQuestions['All'].forEach(sub => {
            if (mockExamSubjects.length === 0) {
                mockExamSubjects.push({
                    id: sub.subjectName,
                    name: sub.subjectName,
                    correctCount: sub.isCorrect ? 1 : 0,
                    answeredCount: 1,
                })
            } else {
                const subjectIndex = mockExamSubjects.findIndex(s => s.id === sub.subjectName)
                if (subjectIndex < 0) {

                    mockExamSubjects.push({
                        id: sub.subjectName,
                        name: sub.subjectName,
                        correctCount: sub.isCorrect ? 1 : 0,
                        answeredCount: 1,
                    })

                } else {
                    const mockExamSubjectObject = mockExamSubjects[subjectIndex]
                    if (mockExamSubjectObject && sub.isCorrect) {
                        mockExamSubjectObject.correctCount += 1
                    }

                    if (mockExamSubjectObject) {
                        mockExamSubjectObject.answeredCount += 1
                    }

                }
            }
        })

        return mockExamSubjects
    }

    get currentTabQuestions () {
        return this.allTabQuestions[this.activeTab]
    }

    get questionCounts () {
        return {
            'All': this.allTabQuestions.All.length,
            'Flagged': this.allTabQuestions.Flagged.length,
            'Incorrect': this.allTabQuestions.Incorrect.length,
            'Correct': this.allTabQuestions.Correct.length,
        } as const
    }

    get correctCount () {
        return this.currentQuiz?.correctCount || 0
    }

    get incorrectCount () {
        return this.currentQuiz?.incorrectCount || 0
    }

    get totalCount () {
        return this.correctCount + this.incorrectCount
    }

    get quizScore () {
        if (!this.currentQuiz) {
            return null
        }
        return Math.round((this.correctCount / (this.totalCount || Infinity)) * 100)
    }

    get scoreColor () {
        if (this.quizScore === null) {
            return null
        }

        const range = this.getScoreRange(this.quizScore)
        return range === 'low' ? 'red' : range === 'medium' ? 'yellow' : 'green'
    }

    get timePerQuestion () {
        const durationSeconds = this.currentQuiz?.durationSeconds || 0
        const secondsPerQuestion = Math.round(durationSeconds / (this.totalCount || Infinity))
        const minutes = Math.floor(secondsPerQuestion / 60)
        const seconds = secondsPerQuestion % 60
        return minutes ? `${minutes}m ${seconds}s` : `${seconds}s`
    }

    get quizTime () {
        const { hours, minutes, seconds } = getHoursMinutesSeconds(this.currentQuiz?.durationSeconds || 0)
        return hours ?
            `${hours}h ${minutes}m`
            : minutes
                ? `${minutes}m ${seconds}s`
                : `${seconds}s`
    }

    get communityScore () {
        if (this.isLevelUpQuiz) {
            return null
        } else {
            return globalQuestionMetricModule.getters.getQuizCommunityAverage(this.quizId)
        }
    }

    get gaugeSize () {
        const breakpoint = screenModule.getters.getBreakpoint()
        return breakpoint === 'black-bear' ? 'large' : 'medium'
    }

    get numSubjectsWithAnswers () {
        return this.currentQuizQuestions.map(question => {
            if ('name' in question.subject) {
                return question.subject.name
            }
            return
        }).length
    }

    get todaysQotD () {
        return qotdModule.getters.getQotDQuestion()
    }

    get hasActiveSubscription () {
        return subscriptionModule.getters.getSubscriptionForExamId()
    }

    get orderedQuizzes () {
        return quizModule.getters.getQuizzes()
            .sort((a, b) => new Date(b.startedAt.iso).getTime() - new Date(a.startedAt.iso).getTime())
    }

    get isMostRecentQuiz () {
        return this.orderedQuizzes[0]?.objectId === this.currentQuiz?.objectId
    }

    get weeklyAnswerHistory () {
        return quizModule.getters.getWeeklyAnswerHistory()
    }

    get currentWeekAnswerCount () {
        return quizModule.getters.getCurrentWeekAnswerCount()
    }

    get earnedWeekly100Badge () {
        const submittedAnswerCount = this.currentQuiz?.answers.length || 0
        const weeklyAnswerCountAfterQuiz = this.currentWeekAnswerCount
        const weeklyAnswerCountBeforeQuiz = weeklyAnswerCountAfterQuiz - submittedAnswerCount
        const earnedWeekly100Badge = weeklyAnswerCountAfterQuiz >= 100 && weeklyAnswerCountBeforeQuiz < 100
        return earnedWeekly100Badge
    }

    get takenQuizzesNum () {
        return quizModule.getters.getQuizzes() ? quizModule.getters.getQuizzes().length : 0
    }

    get quizMessageConfig () {
        return userExamMetadataModule.getters.getMessageReferralConfig()?.quizResults
    }

    get hasSeenCTAThisMonth () {
        const currentMonth = new Date().getMonth()
        const quizLastSeenMonth = this.quizMessageConfig?.lastSeen?.iso && 
           new Date(this.quizMessageConfig.lastSeen.iso).getMonth()
        return currentMonth === quizLastSeenMonth
    }

    get showQuizCTA () {
        // showQuizCTA and showMockExamCTA are called in multiple spots
        // go ahead and return false for showQuizCTA is quizMode is ME
        if (this.isMockExam) {
            return false
        }

        // Show only after taking a quiz not after reviewing a quiz
        if (!this.fromName) {
            return false
        }

        // Show to subscribed premium users (not users studying free exams)
        if (typeof this.hasActiveSubscription !== 'object') {
            return false
        }

        // Do not show if user has seen the quiz || mock exam CTA during current month
        if (this.hasSeenCTAThisMonth) {
            return false
        }

        // Has taken 20+ quizzes
        if (this.takenQuizzesNum < 20) {
            return false
        }

        // Scored 100%
        if (!this.isHundredPercent) {
            return false
        }

        return true
    }

    get showMockExamCTA () {
        // showQuizCTA and showMockExamCTA are called in multiple spots
        // go ahead and return false for showMockExamCTA is quizMode is !ME
        if (!this.isMockExam) {
            return false
        }

        // Show only after taking a quiz not after reviewing a quiz
        if (!this.fromName) {
            return false
        }

        // Show to subscribed premium users (not users studying free exams)
        if (typeof this.hasActiveSubscription !== 'object') {
            return false
        }

        // Do not show if user has seen the quiz || mock exam CTA during current month
        if (this.hasSeenCTAThisMonth) {
            return false
        }

        // Scored 80% or higher
        if ((this.correctCount / this.totalCount) <= .80) {
            return false
        }

        return true
    }

    get showWeekly100Banner () {
        return !this.isLoading
            && this.badgeExperimentVariantValue !== 'badges-not-visible'
            && this.hasActiveSubscription
            && this.isMostRecentQuiz
            && this.earnedWeekly100Badge
    }

    get currentExam () {
        return examMetadataModule.getters.getCurrentExamMetadata()
    }
    
    get currentBundle () {
        return bundleModule.getters.getCurrentBundle()
    }

    get secondsBetweenRetakes () {
        const quizIso = this.currentQuiz?.createdAt
        if (quizIso) {
            const now = new Date()
            const quizDate = new Date(quizIso)
            const differenceInSeconds = (now.getTime() - quizDate.getTime()) / 1000
            return Math.round(differenceInSeconds)
        }
        return 0
    }

    get prebuiltQuiz () {
        return this.currentQuiz?.prebuiltQuiz
    }

    get levelUpQuizLevel () {
        return this.currentQuiz?.levelUpProgress?.level
    }

    get levelUpQuizSubject () {
        return this.currentQuiz?.levelUpProgress?.subjectName
    }

    get numOfLevelsInSubject () {
        return examMetadataModule.getters.getLevelsInSubject(this.levelUpQuizSubject)?.length
    }

    async mounted () {
        await Promise.all([
            userModule.actions.fetchUserData(),
            userExamMetadataModule.actions.fetchUserExamMetadata(),
            questionModule.actions.fetchSerialQuestionInfoLib(),
            quizModule.actions.fetchAnsweredQuestions(),
            globalQuestionMetricModule.actions.fetchGlobalQuestionMetrics(),
            qotdModule.actions.fetchCurrentQotDQuestion(),
            bundleModule.actions.fetchBundles(),
            examMetadataModule.actions.fetchExamMetadata(),  
            quizModule.actions.fetchPrebuiltQuizzes(),
        ])

        // if for some reason we don't have all the questions for the specific quiz, fetch them
        if (this.allTabQuestions['All'].length !== this.currentQuizAnswers.length) {
            await quizModule.actions.fetchAnsweredQuestions(this.quizId)
        }

        // Fetch badge experiment variant if user is premium
        if (this.hasActiveSubscription) {
            const badgeExperimentVariant = await analyticsModule.actions.amplitudeFetchVariant('badges-experiment')
            const badgeExperimentVariantValue = badgeExperimentVariant?.value as 'badges-not-visible' | 'badges-visible'
            this.badgeExperimentVariantValue = badgeExperimentVariantValue || null
        }

        window.addEventListener('keydown', this.keydownListener)

        if (typeof this.$route.query.type === 'string') {
            this.activeTab = this.$route.query.type as TTabName
        }
        if (typeof this.$route.query.from === 'string') {
            this.fromName = this.$route.query.from
        }

        if (Object.keys(this.$route.query).length && this.quizId) {
            this.$router.replace({
                name: 'quiz-result',
                params: { 
                    quizId: this.quizId,
                },
            })
        }

        // if for some reason the quiz you're trying to view doesn't exist, redirect back to study
        if (!this.currentQuiz) {
            if (this.$route.name !== 'study') {
                this.$router.push({
                    name: 'study',
                })
            }
        }

        this.showQuizCTABanner = this.showQuizCTA
        this.showMockExamCTABanner = this.showMockExamCTA

        this.isLoading = false

        this.$nextTick(() => {
            const titleEl = document.querySelector('.quiz-result-header__mode-name') as HTMLElement | null
            titleEl?.focus()
        })

        this.confettiTimerInterval = setInterval(() => {
            this.confettiTime = this.confettiTime + 1
            if (this.confettiTimerInterval && this.confettiTime === 2) {
                this.showConfetti = false
                clearInterval(this.confettiTimerInterval)
            }
        }, 1000)
    }

    beforeUnmount () {
        window.removeEventListener('keydown', this.keydownListener)
        if (this.confettiTimerInterval) {
            clearInterval(this.confettiTimerInterval)
        }
    }

    keydownListener (e: KeyboardEvent) {
        if (e.code === 'Escape') {
            e.stopPropagation()
            this.closeResults()
        }
    }

    retakeQuiz () {
        if (!this.currentQuiz) {
            throw new Error('QuizResult.retakeQuiz: No currentQuiz found')
        }

        const code = String(this.currentQuiz.mode) as keyof typeof utils.studyModes
        const mode = utils.studyModes[code].shortName
        const quizQuestions = [ ...this.currentQuizQuestions ]
        const answerSeeds = new Array(quizQuestions.length).fill(undefined).map(() => {
            return [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 ].sort(() => Math.random() - 0.5)
        })

        const quizToRetake: TActiveQuiz = {
            questions: quizQuestions,
            answers: [],
            examGuid: this.currentQuiz.examGuid,
            mode,
            startedAt: localISODateString(),
            currentQuestionIndex: 0,
            durationSeconds: 0,
            breakDurationSeconds: 0,
            timerType: 'exam',
            answerSeeds,
        }

        // If retaking a timed quiz, give enough minutes to answer the questions according to allowedQuestionsPerMinute
        if (mode === 'timed') {
            quizToRetake.timeLimit = Math.ceil(quizQuestions.length / allowedQuestionsPerMinute)
        }

        quizModule.actions.updateActiveQuiz(quizToRetake)

        analyticsModule.actions.amplitudeTrack('Quiz Retaken', {
            bundleId: this.currentBundle?.objectId || '',
            client: 'Web Study',
            clientAppId: 'study.pocketprep.com',
            clientVersion: this.version,
            examGuid: this.currentExam?.examGuid || '',
            organizationId: userModule.getters.getCurrentOrganizationId() || undefined,
            userDomain: userModule.getters.getUserDomain() || undefined,
            retakeScreen: 'Quiz Results',
            retakeSeconds: this.secondsBetweenRetakes,
        })

        this.$router.push({ name: 'quiz' })
    }

    closeResults () {
        if (this.fromName && [ 'quiz-result', 'byoq', 'mock-exam-intro', 'level-up-intro' ].includes(this.fromName)) {
            this.$router.push({ name: 'study' })
        } else if (this.fromName) {
            this.$router.push({ name: this.fromName })
        } else {
            this.$router.push({ name: 'stats' })
        }
    }

    questionClicked (question: TQuestionCardInfo) {
        this.$router.push({
            name: 'question-review',
            query: {
                ...this.$route.query,
                questionSerial: question.serial,
                quizId: this.quizId,
                type: this.activeTab,
            },
        })
    }

    scenarioPartLabel (question: Study.Class.QuestionJSON) {
        return questionModule.getters.getQuestionScenarioPartLabel(question)
    }

    goToLevelUp () {
        this.$router.push({
            name: 'level-up-intro',
            query: {
                step: 'selectSubject',
            },
        })
    }

    async updateQuizMessageConfig () {
        if (!this.uem) {
            return
        }
        await userExamMetadataModule.actions.upsertUserExamMetadata({
            examGuid: this.uem.examGuid,
            messageConfig: {
                ...(this.uem.messageConfig || {}),
                referral: {
                    ...(this.uem.messageConfig?.referral as Study.Class.MessageConfigOptions || {}),
                    quizResults: {
                        lastSeen: new Date(),
                    },
                },
            },
        })
    }

    trackReferralEvent (trackingMessage: string) {
        logger.info(trackingMessage, {
            examName: this.currentExam?.nativeAppName,
            bundleName: this.currentBundle?.name,
            trackingCampaign: 'ReferralProgramV3',
        }, 'ReferralProgramV3')
    }

    async goToSharePage () {
        if (!this.isMockExam) {
            this.trackReferralEvent('Quiz results notification click')
        } else {
            this.trackReferralEvent('Mock exam results notification click')
        }
    
        this.$router.push({
            name: 'share',
            query: {
                utm_content: 'quiz_results',
            },
        })
    }

    @Watch('showQuizCTABanner')
    @Watch('showMockExamCTABanner')
    showCTABannerChange () {
        if (!this.isLoading && (this.showQuizCTABanner || this.showMockExamCTABanner)) {
            this.updateQuizMessageConfig()
        }
    }
}
</script>

<style lang="scss" scoped>
.quiz-result {
    position: relative;
    background-color: $gray-background;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;

    &__celebration-gif {
        position: absolute;
        transform: translateX(-60%);
        top: -30px;
        z-index: -1;
        width: 480px;
        height: 350px;

        @include breakpoint(brown-bear) {
            margin-left: 30px;
            top: -50px;
            transform: translateY(-10%);
            transform: translateX(-0%);
        }
        
        @include breakpoint(black-bear) {
            top: -60px;
            width: 510px;
            height: 435px;
            margin: auto;
        }

        @media (prefers-reduced-motion: reduce) {
            display: none
        }
    }

    &--dark {
        background-color: $charcoal;
    }

    &__blob {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__banner {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        width: 100%;
        padding-top: 129px;
        background-color: $white;
        z-index: 1;

        &--dark {
            background-color: $brand-black;
        }

        @include breakpoint(brown-bear) {
            padding-top: 105px;
        }
    }

    &__stats {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 780px;

        @include breakpoint(brown-bear) {
            max-width: 500px;
        }
    }

    &__small-stats {
        display: flex;

        @include breakpoint(brown-bear) {
            margin-top: 105px;
        }
    }

    &__mobile-stats {
        display: none;
        justify-content: space-between;
        width: 336px;
        color: $white;
        margin-bottom: 26px;
        margin-top: 34px;

        @include breakpoint(black-bear) {
            display: flex;
        }
    }

    &__mobile-stat {
        text-align: center;
        width: 161px;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        flex-direction: column;

        &--red {
            background: $red-hint;

            &--dark {
                background: rgba($brand-red, 0.3);
            }
        }

        &--yellow {
            background: $cream-puff;

            &--dark {
                background: rgba($butterscotch, 0.18);
            }
        }

        &--green {
            background: $barely-green;

            &--dark {
                background: rgba($jungle-green, 0.2);
            }
        }
    }

    &__mobile-stat-number {
        font-size: 26px;
        letter-spacing: 0.26px;
        margin-bottom: -2px;
        font-weight: 600;
        line-height: 30px;
        color: $brand-black;

        &--dark {
            color: $white;
        }
    }

    &__mobile-stat-label {
        font-size: 14px;
        line-height: 19px;
        color: $ash;

        &--dark {
            color: $white;
        }
    }

    &__mobile-cta {
        width: 176px;
        display: none;

        @include breakpoint(black-bear) {
            display: block;

            &--show-banner {
                margin-top: 24px;
            }
        }
    }

    &__gauge {
        position: relative;
        top: 12px;
        margin-right: 58px;

        @include breakpoint(brown-bear) {
            margin-right: 0;
        }
    }

    &__correct {
        width: 130px;
        margin-right: 48px;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__correct-slash {
        color: $pewter;
        margin: 0 2px;
    }

    &__time-per-question {
        width: 140px;
        margin-right: 38px;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__quiz-time {
        width: 140px;

        @include breakpoint(black-bear) {
            display: none;
        }
    }

    &__share-cta,
    &__weekly-100-banner {
        display: flex;
        border-radius: 6px;
        margin-top: 80px;
        width: 780px;

        @include breakpoint(brown-bear) {
            margin-top: 40px;
            max-width: 708px;
        }
    }

    &__share-cta {
        background-color: $manilla;
        border: 1px solid $banana-bread;

        &--dark {
            border: 1px solid rgba($cheddar, 0.50);
            background: rgba($cheddar, 0.20);
        }

        @include breakpoint(black-bear) {
            max-width: 343px;
            height: 65px;
            margin-top: 6px;
        }
    }

    &__weekly-100-banner {
        background-color: $barely-green;
        border: 1px solid rgba($brand-green, 0.4);

        &--dark {
            background-color: rgba($green, 0.2);
        }

        @include breakpoint(black-bear) {
            max-width: 343px;
            height: 45px;
            margin-top: 6px;
            margin-bottom: 24px;
        }
    }

    &__tada {
        height: 22px;
        max-width: 20px;
        margin: 12px 0 0 19px;
    }

    &__badge-icon {
        width: 28px;
        height: 28px;
        color: $cheddar;
        margin: 8px 0 8px 8px;

        &--dark {
            color: $banana-bread;
        }
    }

    &__share-cta-message,
    &__weekly-100-message {
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        display: flex;
        align-items: center;
        padding: 12px 20px 12px 10px;

        &--dark {
            color: $white;
        }

        @include breakpoint(black-bear) {
            display: block;
            font-weight: 500;
            margin-left: 6px;
            line-height: 22px;
            padding: 0;
            margin-top: 12px;
        }
    }

    &__share-cta-btn {
        margin-left: 145px;

        &--mock-exam {
            margin-left: 210px;
        }

        @include breakpoint(brown-bear) {
            margin-left: 75px;

            &--mock-exam {
                margin-left: 140px;
            }
        }

        @include breakpoint(black-bear) {
            margin-left: 0;
        }
    }

    &__subject-insights {
        margin-top: 74px;
        margin-bottom: 49px;
        width: 812px;

        &--banner {
            margin-top: 40px;
        }

        @include breakpoint(brown-bear) {
            margin-top: 60px;
            margin-bottom: 68px;
            width: 725px;

            &--banner {
                margin-top: 40px;
            }
        }

        @include breakpoint(black-bear) {
            margin-top: 46px;
            margin-bottom: 44px;
            width: 370px;
        }
    }

    &__tabs {
        display: flex;
        width: 780px;
        margin-top: 120px;

        @include breakpoint(brown-bear) {
            width: 708px;
        }

        @include breakpoint(black-bear) {
            margin-top: 52px;
            width: 336px;
        }

        &--mock-exam {
            margin: auto;
        }

        &--show-banner {
            margin-top: 44px;
        }
    }

    &__blank {
        text-align: center;
        padding-top: 56px;

        :deep(.uikit-empty-state) {
            margin: auto;
        }
    }

    &__questions {
        padding-top: 34px;
        padding-bottom: 55px;
        margin: 0 auto;
        width: 780px;

        @include breakpoint(brown-bear) {
            width: 708px;
        }

        @include breakpoint(black-bear) {
            width: 336px;
        }
    }
}
</style>
