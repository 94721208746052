import { Parse } from '@/store/parseUtils'
import type { Study } from '@pocketprep/types'
import { fetchLoadable } from '@/store/utils'
import { examMetadataModule } from '@/store/examMetadata/module'

const fetchExamMetadata = async () => {
    const examLoadable = examMetadataModule.state.examMetadata

    await fetchLoadable(examLoadable, async () => {
        const examMetadata = await new Parse.Query<Study.Class.ExamMetadata>('ExamMetadata')
            .findAll({ batchSize: 500 })
        return examMetadata.map(e => e.toJSON())
    })
}

export default {
    fetchExamMetadata,
}
