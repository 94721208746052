<template>
    <div 
        v-dark 
        class="subject-insights__container"
        :class="{ 'subject-insights__container--mock-exam': isMockExam}"
    >
        <div
            v-if="breakpoint === 'black-bear'"
            class="subject-insights"
            :class="{ 'subject-insights--expanded': expandSubjects}"
        >
            <div v-dark class="subject-insights__header">
                <Icon type="subject" />
                <h2>Subject Insights</h2>
            </div>
            <SubjectCard
                v-for="(subject, index) in subjects"
                :key="subject.id"
                :subject="subject"
                class="subject-insights__subject"
                :style="{ display: index > 7 && !expandSubjects ? 'none' : 'block' }"
            />
        </div>
        <Table
            v-else
            :key="isLoading"
            class="subject-insights"
            :class="{ 'subject-insights--is-mock-exam': isMockExam}"
            theme="open"
            :show-header="false"
            :rows="expandSubjects ? subjects : subjects.slice(0, numSubjectsVisible)"
            :columns="tableColumns"
            :default-sort="defaultSort"
            :is-dark-mode="isDarkMode"
            @sort="updateSortSettings"
        >
            <template #tableColumnLabelText="{ column }">
                <template v-if="column.propName === 'name'">
                    <div class="subject-insights__column-label-large">
                        <Icon
                            v-dark
                            type="subject"
                            class="subject-insights__column-label-large-icon"
                        />
                        <h2 v-dark class="subject-insights__column-label-large-text">
                            Subject Insights
                        </h2>
                    </div>
                </template>
            </template>
            <template #tableCellValue="{ row, column }">
                <template v-if="column.propName === 'name'">
                    <div v-dark class="subject-insights__cell-name">
                        <OverflowTooltip>
                            {{ row.name }}
                        </OverflowTooltip>
                    </div>
                </template>
                <template v-if="column.propName === 'correctAnswered'">
                    <div v-dark class="subject-insights__cell-correct-answered">
                        {{ row.correctCount }} <span>/</span> {{ row.answeredCount }}
                    </div>
                </template>
                <template v-if="column.propName === 'score'">
                    <div class="subject-insights__cell-score">
                        <div class="subject-insights__cell-score-percent">
                            <template v-if="row.answeredCount">
                                {{ Math.round((row.correctCount / row.answeredCount) * 100) }}
                            </template>
                            <template v-else>
                                - -
                            </template>
                            <span> %</span>
                        </div>
                        <div v-dark class="subject-insights__cell-score-bar">
                            <div
                                :key="`${row.correctCount / row.answeredCount}_${row.name}`"
                                v-dark
                                class="subject-insights__cell-score-bar-fill"
                                :class="{
                                    [`subject-insights__cell-score-bar-fill--${
                                        getScoreRange(Math.round((row.correctCount / row.answeredCount) * 100))
                                    }`]: true
                                }"
                                :style="{ width: `${Math.round((row.correctCount / row.answeredCount) * 100)}%` }"
                            />
                        </div>
                    </div>
                </template>
            </template>
        </Table>
        <PocketButton
            v-if="subjects.length > numSubjectsVisible"
            type="tertiary-small"
            class="subject-insights__expand"
            :class="{ 'subject-insights__expand--expanded': expandSubjects }"
            :is-dark-mode="isDarkMode"
            :aria-label="`
                ${ expandSubjects ? 'Hide' : 'View' } ${ subjects.length - numSubjectsVisible } 
                ${ expandSubjects ? `subject${subjects.length > 9 ? 's' : ''}` : 'more' }
            `"
            @click="expandSubjects = !expandSubjects"
        >
            {{ expandSubjects ? 'Hide' : 'View' }} {{ subjects.length - numSubjectsVisible }} 
            {{ expandSubjects ? `subject${subjects.length > 9 ? 's' : ''}` : 'more' }}
            <Icon type="accordionArrow" />
        </PocketButton>
    </div>
</template>

<script lang="ts">
import { examMetadataModule } from '@/store/examMetadata/module'
import { questionModule } from '@/store/question/module'
import { quizModule } from '@/store/quiz/module'
import { userModule } from '@/store/user/module'
import UIKit, { type ITableSortSettings } from '@pocketprep/ui-kit'
import { Vue, Component, Prop } from 'vue-facing-decorator'
import { getScoreRange } from '@/utils'
import SubjectCard, { type TSubject } from '@/components/Stats/SubjectCard.vue'
import { screenModule } from '@/store/screen/module'
import { subscriptionModule } from '@/store/subscription/module'

type TSubjectLib = { 
    [subjectName: string]: TSubject & { id: string }
}
type TMockExamSubjects = {
    id: string
    name: string
    correctCount: number
    answeredCount: number
}

@Component({
    components: {
        Table: UIKit.Table,
        Icon: UIKit.Icon,
        SubjectCard,
        PocketButton: UIKit.Button,
        OverflowTooltip: UIKit.OverflowTooltip,
    },
})
export default class SubjectInsights extends Vue {
    @Prop({ default: null }) quizId!: string | null
    @Prop({ default: 8 }) numSubjectsVisible!: number
    @Prop({ default: false }) isMockExam!: boolean
    @Prop({ default: null }) mockExamSubjectInsights!: TMockExamSubjects[]

    isLoading = true
    sortSettings: ITableSortSettings | null = null
    defaultSort: ITableSortSettings | null = null
    getScoreRange = getScoreRange
    expandSubjects = false
    tableColumns = [{
        name: 'Subject Insights',
        propName: 'name',
    }, {
        name: this.isMockExam ? 'Correct / Total' : 'Correct / Answered',
        propName: 'correctAnswered',
        isSortDisabled: true,
    }, {
        name: 'Subject Score',
        propName: 'score',
    }]

    get breakpoint () {
        return screenModule.getters.getBreakpoint()
    }

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get hasSubscription () {
        return subscriptionModule.getters.getSubscriptionForExamId()
    }

    get subjects () {
        const examSubjects = examMetadataModule.state.subjectsWithLevels
        if (!examSubjects) {
            return []
        }
        const defaultSubjectLib = examSubjects.reduce<TSubjectLib>((acc, s) => {
            acc[s.subjectId] = {
                id: s.subjectId,
                name: s.subjectName,
                correctCount: 0,
                answeredCount: 0,
            }
            return acc
        }, {})

        const answers = this.quizId
            ? quizModule.getters.getLatestAnswers({ quizId: this.quizId, questionFilter: 'all' })
            : quizModule.getters.getLatestAnswers({ questionFilter: 'all' })

        const serialQuestionInfoLib = questionModule.getters.getSerialQuestionInfoLib({ questionFilter: 'all' })
        const subjectLib = Object.entries(answers).reduce<TSubjectLib>((acc, [ serial, answer ]) => {
            const subjectId = serialQuestionInfoLib[serial]?.subjectId
            if (!subjectId) {
                return acc
            }
            if (!(subjectId in acc)) {
                return acc
            }
            const subjectObject = acc[subjectId]
            if (subjectObject) {
                subjectObject.correctCount += answer.isCorrect ? 1 : 0
                subjectObject.answeredCount += 1
            }

            return acc
        }, defaultSubjectLib)

        const subjectInsightType = this.isMockExam ? this.mockExamSubjectInsights : subjectLib

        return Object.values(subjectInsightType)
            .sort((a, b) => {
                const sort = this.sortSettings

                if (sort?.column?.propName === 'name') {
                    return sort.direction * a.name.localeCompare(b.name)
                }
                if (sort?.column?.propName === 'correctAnswered') {
                    return sort.direction * (a.correctCount - b.correctCount)
                }
                if (sort?.column?.propName === 'score') {
                    const aScore = (a.correctCount / a.answeredCount) * 100
                    const bScore = (b.correctCount / b.answeredCount) * 100
                    return isNaN(aScore)
                        ? 1
                        : isNaN(bScore)
                            ? -1
                            : sort.direction * (aScore - bScore)
                }

                return 0
            })
    }

    async mounted () {
        await Promise.all([
            questionModule.actions.fetchSerialQuestionInfoLib(),
            userModule.actions.fetchUserData(),
            examMetadataModule.actions.fetchExamMetadata(),
        ])

        const sortPreference = userModule.state.settings.subjectInsightsSort

        if (sortPreference) {
            this.defaultSort = sortPreference === 'alphabetical'
                ? { column: { propName: 'name', name: 'Name' }, direction: 1 }
                : sortPreference === 'weakest'
                    ? { column: { propName: 'score', name: 'Subject Score' }, direction: 1 }
                    : { column: { propName: 'score', name: 'Subject Score' }, direction: -1 }
            this.sortSettings = JSON.parse(JSON.stringify(this.defaultSort))
        }

        this.isLoading = false
    }

    updateSortSettings (sortSettings: ITableSortSettings) {
        this.sortSettings = sortSettings

        if (this.sortSettings.direction === 0) {
            this.sortSettings.direction = 1
        }
    }
}
</script>

<style lang="scss" scoped>
.subject-insights {
    :deep(.uikit-table__column-labels),
    :deep(.uikit-table__row) {
        padding-left: 8px;
    }

    :deep(.uikit-table__column-label) {
        &:nth-child(1) {
            svg.uikit-table__sort-icon {
                position: absolute;
                top: 7px;
                right: -18px;
            }

            .uikit-table__column-label-content::before {
                width: calc(100% + 28px);
            }
        }

        &:nth-child(2) {
            svg {
                position: absolute;
                top: 3px;
                right: -15px;
            }

            .uikit-table__column-label-content::before {
                width: calc(100% + 22px);
            }
        }

        &:nth-child(2),
        &:nth-child(3) {
            margin-top: 4px;
        }
    }

    :deep(.uikit-table__column-label),
    :deep(.uikit-table__cell) {
        &:nth-child(1) {
            width: 452px;

            @include breakpoint(brown-bear) {
                width: 318px;
            }
        }

        &:nth-child(2) {
            width: 114px;
            text-align: right;
            margin-right: 24px;

            @include breakpoint(brown-bear) {
                width: 111px;
                margin-right: 20px;
            }
        }

        &:nth-child(3) {
            width: 309px;

            @include breakpoint(brown-bear) {
                width: 200px;
            }
        }
    }

    &--is-mock-exam {
        :deep(.uikit-table__column-labels),
        :deep(.uikit-table__row) {
            padding-left: 8px;
        }

        :deep(.uikit-table__column-label) {
            &:nth-child(1) {
                svg.uikit-table__sort-icon {
                    position: absolute;
                    top: 7px;
                    right: -18px;
                }

                .uikit-table__column-label-content::before {
                    width: calc(100% + 28px);
                }
            }

            &:nth-child(2) {
                svg {
                    position: absolute;
                    top: 3px;
                    right: -15px;
                }

                .uikit-table__column-label-content::before {
                    width: calc(100% + 22px);
                }
            }

            &:nth-child(2),
            &:nth-child(3) {
                margin-top: 4px;
            }
        }

        :deep(.uikit-table__column-label),
        :deep(.uikit-table__cell) {
            &:nth-child(1) {
                width: 400px;

                @include breakpoint(brown-bear) {
                    width: 318px;
                }
            }

            &:nth-child(2) {
                width: 90px;
                text-align: right;
                margin-right: 24px;

                @include breakpoint(brown-bear) {
                    width: 111px;
                    margin-right: 20px;
                }
            }

            &:nth-child(3) {
                width: 230px;

                @include breakpoint(brown-bear) {
                    width: 200px;
                }
            }
        }
    }

    &__expand {
        padding: 10px 11px;

        svg {
            margin-left: 3px;
        }

        &--expanded {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    &__container {
        width: 975px;
        background: $white;
        border-radius: 6px;
        padding: 8px 16px;
        margin: 29px auto 12px;
        box-sizing: border-box;
        position: relative;

        @include breakpoint(brown-bear) {
            width: 725px;
            margin-bottom: 16px;
        }

        @include breakpoint(black-bear) {
            width: 100%;
            background: transparent;
            padding: 0 18px;
            margin: 30px auto 20px;
        }

        &--dark {
            background: rgba($white, 0.02);

            @include breakpoint(black-bear) {
                background: transparent;
            }
        }

        &--mock-exam {
            &--dark {
                background-color: $brand-black;
            }
        }
    }

    &__column-label-large {
        display: flex;
        align-items: center;
    }

    &__column-label-large-icon {
        height: 23px;
        margin-right: 8px;
        color: $slate;

        &--dark {
            color: $pewter;
        }
    }

    &__column-label-large-text {
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        color: $brand-black;
        margin: 0;

        &--dark {
            color: $fog;
        }
    }

    &__cell-name {
        font-size: 16px;
        line-height: 19px;
        color: $slate-03;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &--dark {
            color: $white;
        }
    }

    &__cell-correct-answered {
        font-size: 16px;
        letter-spacing: -0.3px;
        font-weight: 400;
        color: $slate-03;

        &--dark {
            color: $white;
        }

        span {
            font-weight: 500;
            letter-spacing: 0.43px;
            color: $pewter;
        }
    }

    &__cell-score-percent {
        font-size: 18px;
        line-height: 19px;
        letter-spacing: -0.3px;
        font-weight: 600;
        margin-right: 14px;
        min-width: 50px;
        text-align: right;

        span {
            letter-spacing: 0.48px;
            font-weight: 400;
            margin-left: -2px;
        }
    }

    &__cell-score {
        display: flex;
        align-items: center;
    }

    &__cell-score-bar {
        background: $gray-progress-background;
        max-width: 248px;
        width: 100%;
        height: 14px;
        border-radius: 2px;
        position: relative;
        border: 1px solid rgba($pewter, 0.85);

        &--dark {
            background: $moonlit-ocean;
            border-color: $slate;
        }
    }

    &__cell-score-bar-fill {
        position: absolute;
        top: -1px;
        height: 14px;
        border-radius: 2px;
        border: 1px solid $pepper;

        &--low {
            background: $tomato;

            &--dark {
                border: none;
            }
        }

        &--medium {
            background: $cheddar;
            border-color: $golden-hind;

            &--dark {
                border: none;
            }
        }

        &--high {
            background: $field;
            border-color: $spectral-green;

            &--dark {
                background: $jungle-green;
                border: none;
            }
        }
    }

    &__subject {
        margin-bottom: 11px;

        &--overflow {
            display: none;
        }
    }

    &__header {
        display: flex;
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        align-content: center;
        margin-bottom: 9px;
        padding: 0 11px;

        &--dark {
            color: $fog;
        }

        h2 {
            margin: 0;
            font-size: 16px;
            font-weight: 600;
        }

        svg {
            margin-right: 8px;
        }
    }
}
</style>