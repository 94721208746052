<template>
    <div v-dark class="subjects-selector">
        <div v-dark class="subjects-selector__subjects-head">
            <div
                v-dark
                class="subjects-selector__subjects-head-left"
                tabindex="0"
                role="button"
                :aria-expanded="showSubjects ? 'true' : 'false'"
                @click="showSubjects = !showSubjects"
                @keydown.enter="showSubjects = !showSubjects"
                @mousedown.prevent
            >
                <Icon
                    v-dark
                    type="subject"
                    class="subjects-selector__subjects-head-left-subject"
                />
                <label
                    id="subjects-selector__subjects-head-left-subject-label"
                    class="subjects-selector__subjects-head-left-subject-label"
                >All Subjects</label>
                <Icon
                    v-dark
                    type="accordionArrow"
                    class="subjects-selector__subjects-head-left-accordion"
                    :class="{ 'subjects-selector__subjects-head-left-accordion--asc': showSubjects }"
                />
            </div>
            <div v-dark class="subjects-selector__subjects-head-right">
                <div class="subjects-selector__subjects-count">
                    {{ selectedSubjectIds && selectedSubjectIds.length }} of {{ subjects && subjects.length }}
                </div>
                <Checkbox 
                    :modelValue="allSubjectsSelected" 
                    class="subjects-selector__subjects-head-checkbox"
                    :is-dark-mode="isDarkMode"
                    aria-labelledby="subjects-selector__subjects-head-left-subject-label"
                    @click.stop="selectAllSubjects"
                    @keydown.enter="selectAllSubjects"
                    @mousedown.prevent
                />
            </div>
        </div>
        <div
            v-if="showSubjects"
            class="subjects-selector__subject-options"
            aria-live="polite"
        >
            <div 
                v-for="subject in subjects"
                :key="subject.subjectId"
                v-dark
                class="subjects-selector__subject-option"
                :class="{
                    'subjects-selector__subject-option--selected': selectedSubjectIds.includes(subject.subjectId) 
                }"
                @click="clickSubject(subject.subjectId)"
                @keydown.enter="clickSubject(subject.subjectId)"
                @mousedown.prevent
            >
                <Checkbox 
                    :modelValue="selectedSubjectIds.includes(subject.subjectId)" 
                    class="subjects-selector__subject-option-checkbox"
                    :is-dark-mode="isDarkMode"
                    :aria-labelledby="
                        `subjects-selector__label-${subject.subjectName.replace(/[^\w!?]/g,'').toLowerCase()}`
                    "
                    @click.stop="clickSubject(subject.subjectId)"
                />
                <label
                    :id="`subjects-selector__label-${subject.subjectName.replace(/[^\w!?]/g,'').toLowerCase()}`"
                    class="subjects-selector__subject-option-label"
                >
                    {{ subject.subjectName }}
                </label>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { examMetadataModule } from '@/store/examMetadata/module'
import { userModule } from '@/store/user/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { difference } from '@/utils'
import type { Study } from '@pocketprep/types'
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Prop, Watch, Emit } from 'vue-facing-decorator'

@Component({
    components: {
        Checkbox: UIKit.Checkbox,
        Icon: UIKit.Icon,
    },
})
export default class SubjectsSelector extends Vue {
    @Prop() examMetadata!: Study.Class.ExamMetadataJSON
    @Prop({ default: true }) initialShowSubjects!: boolean

    selectedSubjectIds: string[] = []
    showSubjects = true

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    get allSubjectsSelected () {
        return this.selectedSubjectIds?.length === this.subjects?.length
            ? true
            : this.selectedSubjectIds?.length
                ? null
                : false
    }

    get subjects () {
        return examMetadataModule.state.subjectsWithLevels.sort((a, b) => 
            a.subjectName.localeCompare(b.subjectName, undefined, { numeric: true })
        )
    }

    get subjectIds () {
        return this.subjects.map(subject => subject.subjectId)
    }

    get disabledSubjects () {
        return difference(this.subjectIds, this.selectedSubjectIds)
    }

    get uemDisabledSubjects () {
        return userExamMetadataModule.getters.getUemDisabledSubjects(this.examMetadata.examGuid, this.subjects)
    }

    async mounted () {
        this.showSubjects = this.initialShowSubjects
        await userModule.actions.fetchUserData()
        this.setSelectedSubjectIds()
        this.emitSelectedSubjectIds(this.selectedSubjectIds)
    }

    @Watch('examMetadata')
    setSelectedSubjectIds () {
        this.selectedSubjectIds = this.uemDisabledSubjects
            ? difference(this.subjectIds, this.uemDisabledSubjects || [])
            : this.subjectIds
    }

    selectAllSubjects () {
        if (this.allSubjectsSelected) {
            this.selectedSubjectIds = []
        } else {
            this.selectedSubjectIds = [ ...this.subjectIds ]
        }

        this.emitSelectedSubjectIds(this.selectedSubjectIds)
        this.emitDisabledSubjects(this.disabledSubjects)
    }

    clickSubject (subjectIds: string) {
        if (this.selectedSubjectIds.includes(subjectIds)) {
            this.selectedSubjectIds.splice(this.selectedSubjectIds.indexOf(subjectIds), 1)
        } else {
            this.selectedSubjectIds = [ ...this.selectedSubjectIds, subjectIds ]
        }

        this.emitSelectedSubjectIds(this.selectedSubjectIds)
        this.emitDisabledSubjects(this.disabledSubjects)
    }

    @Emit('selectedSubjects')
    emitSelectedSubjectIds (selectedSubjectIds: string[]) {
        return selectedSubjectIds
    }

    @Emit('disabledSubjects')
    emitDisabledSubjects (disabledSubjects: string[]) {
        return disabledSubjects
    }
}
</script>
<style lang="scss" scoped>
.subjects-selector {
    border: 1px solid $gray-divider;
    background: $white;
    border-radius: 6px;

    &--dark {
        background: $brand-black;
        color: $white;
        border: 0;
    }

    &__subject-option {
        font-size: 15px;
        line-height: 19px;
        color: $slate-01;
        padding: 14px 50px 13px 15px;
        border-top: 1px solid $gray-divider;
        cursor: pointer;
        user-select: none;
        position: relative;

        &--dark {
            color: $pewter;
            border-top-color: $ash;
            padding: 14px 51px 13px 16px;
        }

        &--selected {
            color: $brand-black;

            &--dark {
                color: $white;
            }
        }

        &:last-child {
            border-bottom: 0;
        }

        svg {
            color: $brand-blue;
            position: absolute;
            width: 16px;
            height: 13px;
            top: 19px;
            right: 20px;
        }
    }

    &__subject-option-checkbox {
        position: absolute;
        top: 50%;
        margin-top: -10.5px;
        right: 12px;
    }

    &__subject-option-label {
        cursor: pointer;
    }

    &__subjects-head {
        display: flex;
        justify-content: space-between;
        padding: 16px 12px 14px 15px;
        align-items: center;

        &--dark {
            padding: 16px 13px 14px 16px;
        }
    }

    &__subjects-head-left {
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        flex: 1;
        outline: none;
        position: relative;

        span {
            display: block;
            font-size: 15px;
            line-height: 20px;
        }

        &:focus::before {
            content: '';
            left: -4px;
            top: -4px;
            width: 100%;
            height: calc(100% + 8px);
            border: 1px solid $brand-blue;
            position: absolute;
            border-radius: 5px;
        }

        &--dark:focus::before {
            border-color: $banana-bread;
        }
    }

    &__subjects-head-left-subject {
        margin-right: 8px;
        height: 21px;

        &--dark {
            color: $pewter;
        }
    }

    &__subjects-head-left-subject-label {
        cursor: pointer;
    }

    &__subjects-head-left-accordion {
        color: $brand-blue;
        margin-left: 6px;

        &--asc {
            transform: rotate(180deg);
        }

        &--dark {
            color: $white;
        }
    }

    &__subjects-head-right {
        display: flex;
        align-items: center;
        color: $slate-01;
        font-size: 14px;
        line-height: 17px;

        &--dark {
            color: $fog;
        }
    }

    &__subjects-count {
        margin-right: 8px;
    }
}
</style>