<template>
    <Portal
        to="sidepanel"
    >
        <SidePanel
            :key="`review-new-versions-${String($.vnode.key)}`"
            width="medium"
            name="Review Versions"
            class="review-new-versions__sidepanel"
            :disable-transition="disableTransition"
            :is-dark-mode="isDarkMode"
            :class="{ 'review-new-versions--full-height': showFullHeight }"
            @close="cancelVersionChange"
        >
            <Errors
                v-if="(!isLoading && errors.length)"
                :is-dark-mode="isDarkMode"
                :errors="[ errors ]"
                class="review-new-versions__error"
            />
            <div 
                class="review-new-versions__label" 
            >
                Which version {{ examName }} are you preparing for?
            </div>
            <ExamVersionCard
                v-for="(examVersion, index) in passedExamVersions"
                :key="examVersion.objectId"
                :exam="examVersion"
                :is-selected-version="
                    selectedMajorVersion && examVersion.version.startsWith(`${selectedMajorVersion}.`)
                "
                :reviewing-versions="true"
                :is-active-exam="currentExamVersion.version.split('.')[0] === examVersion.version.split('.')[0]"
                :is-latest-version="index === 0"
                :is-dark-mode="isDarkMode"
                @clickExamVersion="clickExamVersion"
            />
            <div
                v-if="passedExamVersions.length > 1"
                v-dark
                class="review-new-versions__warning"
            >
                Each exam version has a separate set of questions, explanations, and references. 
                Your stats are tied to an exam version. 
                You can switch between versions without losing your progress.
            </div>
            <template #action>
                <PocketButton
                    :is-dark-mode="isDarkMode"
                    :disabled="selectedMajorVersion === currentExamVersion.version.split('.')[0]"
                    :is-loading="isChangingVersions"
                    @click="changeVersions"
                >
                    Change Version
                </PocketButton>
            </template>
        </SidePanel>
    </Portal>
</template>

<script lang="ts">
import UIKit from '@pocketprep/ui-kit'
import { Vue, Component, Prop, Emit } from 'vue-facing-decorator'
import type { Study } from '@pocketprep/types'
import { bundleModule } from '@/store/bundle/module'
import { userModule } from '@/store/user/module'
import { userExamMetadataModule } from '@/store/userExamMetadata/module'
import { examMetadataModule } from '@/store/examMetadata/module'
import ExamVersionCard from '@/components/Settings/EditExam/ExamVersionCard.vue'
import { toastModule } from '@/store/toast/module'
import { examVersionNativeAppName } from '@/utils'

@Component({
    components: {
        SidePanel: UIKit.SidePanel,
        PocketButton: UIKit.Button,
        PocketLink: UIKit.Link,
        Radio: UIKit.Radio,
        Icon: UIKit.Icon,
        PocketInput: UIKit.Input,
        PocketSelect: UIKit.Select,
        Errors: UIKit.Errors,
        ExamVersionCard,
        ModalContainer: UIKit.ModalContainer,
        Modal: UIKit.Modal,
    },
})
export default class ReviewNewVersions extends Vue {
    @Prop({ default: false }) showFullHeight!: boolean
    @Prop() passedExamVersions!: Study.Class.ExamMetadataJSON[]
    @Prop() currentExamVersion!: Study.Class.ExamMetadataJSON
    @Prop() examMetadata!: Study.Class.ExamMetadataJSON
    @Prop() uem: Study.Class.UserExamMetadataJSON | null = null

    isLoading = true
    errors: string[] = []
    errorFields: string[] = []
    disableTransition = false
    isDisabled = true
    isChangingVersions = false
    selectedExamVersion: string | undefined = ''
    selectedMajorVersion: string | undefined = ''
    examId = ''
    isResetLoading = false
    examName = ''

    get isDarkMode () {
        return userModule.state.settings.isDarkMode
    }

    async mounted () {
        await Promise.all([
            bundleModule.actions.fetchBundles(),
            examMetadataModule.actions.fetchExamMetadata(),
            userModule.actions.fetchUserData(),
            userExamMetadataModule.actions.fetchUserExamMetadata(),
        ])

        this.selectedMajorVersion = this.uem?.examVersion?.split('.')[0]
        this.examName = examVersionNativeAppName(this.examMetadata.nativeAppName)
        this.isLoading = false
    }

    async clickExamVersion (exam: Study.Class.ExamMetadataJSON) {
        this.examId = exam.objectId
        this.selectedExamVersion = exam.version
        this.selectedMajorVersion = exam.version.split('.')[0]
    }

    async changeVersions () {
        if (!this.examId) {
            throw new Error('No selected exam version.')
        }

        if (!this.uem) {
            throw new Error('No current userExamMetadata.')
        }

        if (!this.selectedExamVersion) {
            throw new Error('No exam version selected.')
        }

        if (!this.selectedMajorVersion) {
            throw new Error('No major version selected.')
        }

        this.isLoading = true
        this.isChangingVersions = true

        if (this.uem && this.selectedMajorVersion !== this.currentExamVersion.version.split('.')[0]) {
            if (!this.examMetadata) {
                throw new Error('No exam metadata record found for version/guid.')
            }
            const upgradesSeen = []
            if (this.passedExamVersions) {
                upgradesSeen.push(
                    ...this.passedExamVersions
                        .slice(0, this.passedExamVersions.length - 1)
                        .map(e => e.version.split('.')[0])
                )
            }

            // new version seen
            await userExamMetadataModule.actions.upsertUserExamMetadata({
                objectId: this.uem.objectId,
                upgradesSeen,
            })
            await userModule.actions.updateCurrentExam(this.examId)

            const newUEM = userExamMetadataModule.getters.getCurrentUserExamMetadata()
            if (newUEM?.objectId) {
                // Avoids incorrectly showing the user their current version is "new"
                await userExamMetadataModule.actions.upsertUserExamMetadata({
                    objectId: newUEM.objectId,
                    upgradesSeen,
                })
            }

            this.emitClose()
        } else {
            this.emitClose()
        }

        if (this.selectedMajorVersion !== this.currentExamVersion.version.split('.')[0]) {
            toastModule.actions.displayToast({
                title: `Updated ${this.examMetadata?.nativeAppName} version`,
            })
        }
        this.isLoading = false
        this.isChangingVersions = false
    }

    async cancelVersionChange () {
        if (!this.uem) {
            throw new Error('No current userExamMetadata.')
        }

        const upgradesSeen = []

        upgradesSeen.push(
            ...this.passedExamVersions
                .slice(0, this.passedExamVersions.length - 1)
                .map(e => e.version.split('.')[0])
        )

        await userExamMetadataModule.actions.upsertUserExamMetadata({
            objectId: this.uem?.objectId,
            upgradesSeen,
        })
            
        this.emitClose()
    }

    @Emit('close')
    emitClose () {
        return true
    }

    @Emit('error')
    emitError () {
        return true
    }
}
</script>

<style lang="scss" scoped>
.review-new-versions {
    position: absolute;
    color: $brand-black;

    &--full-height {
        top: 0;
        height: 100%;

        @include breakpoint(black-bear) {
            height: calc(100% - 10px);
        }
    }

    &__modal {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        top: 80px;
        width: 392px;
        padding: 35px 0 26px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include breakpoint(black-bear) {
            width: 356px;
            height: auto;
            max-height: 638px;
            top: 10px;
        }
    }

    &__modal-title {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        width: 309px;
        margin: 0 auto 12px;
    }

    &__modal-message {
        font-size: 15px;
        line-height: 22px;
        color: $slate-03;
        margin-bottom: 29px;
        width: 309px;

        &--dark {
            color: $white;
        }
    }

    &__modal-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__modal-button {
        margin: 0 6px;
    }

    &__error {
        margin: -12px;
        max-width: 396px;
    }

    &__label {
        font-size: 15px;
        line-height: 18px;
        font-weight: 600;
        margin-bottom: 18px;
    }

    &__warning {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 31px;
        color: $ash;

        &--dark {
            color: $fog;
        }
    }
}
</style>